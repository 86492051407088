import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

import {injectIntl} from "react-intl"
import {Link, useNavigate} from 'react-router-dom';
import {pagesNav, pagesCompanyNav, pagesAccNav} from "../../../utils/nav_company";
import Slide from '@mui/material/Slide';
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";

const Dashboard = ({intl}) => {
    const navigate = useNavigate();
    const [showMain, setShowMain] = React.useState(true);
    const [showSub, setShowSub] = React.useState(false);
    const [currentSubs, setCurrentSubs] = React.useState([]);
    const {contact} = useSelector(({company}) => company)
    const urlParams1 = new URLSearchParams(window.location.search);
    const tab1 = urlParams1.get('tab')
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const tab = urlParams.get('tab')
        if (tab == 'null' || tab == undefined) {
            setTimeout(function() {
                setShowMain(true)
                setShowSub(false)
            }, 200)
        }

        if (tab == 'recruitment') {
            setShowMain(false)
            setShowSub(true)
            setCurrentSubs(pagesNav[0].subs)
        }
        if (tab == 'portage') {
            setShowMain(false)
            setShowSub(true)
            setCurrentSubs(pagesNav[1].subs)
        }
        if (tab == 'parainage') {
            setShowMain(false)
            setShowSub(true)
            setCurrentSubs(pagesNav[2].subs)
        }
        if (tab == 'account') {
            setShowMain(false)
            setShowSub(true)
            setCurrentSubs(pagesAccNav[0].subs)
        }
    },[tab1])

    const renderOptions = (options) => {
        let render = (
            <div className={'flex flex-col justify-left'}>
                {options.map((option, index) => (
                    <div key={index} className='text-center'>
                        {/* <i className={'fa fa-check text-green-600 mr-2'}></i>  */}
                        {intl.formatMessage({id: option})}
                    </div>
                ))}
            </div>
        )

        return render;
    }

    const renderSubs = () => {
        let render = (
            <div className={"flex flex-col"}>
                <div className={
                    currentSubs?.length === 3 ?
                        "flex flex-col space-y-4 md:space-y-0 md:grid grid-cols-3 gap-y-4 gap-x-8" :
                        currentSubs?.length === 4 ? "flex flex-col space-y-4 md:space-y-0 md:grid grid-cols-4 gap-y-4 gap-x-8" :
                            currentSubs?.length === 5 ? "flex flex-col space-y-4 md:space-y-0 md:grid grid-cols-5 gap-y-4 gap-x-8" :
                                currentSubs?.length === 6 ? "flex flex-col space-y-4 md:space-y-0 md:grid grid-cols-3 gap-y-4 gap-x-8" :
                                    currentSubs?.length === 2 ? "flex flex-col space-y-4 md:space-y-0 md:grid grid-cols-2 gap-y-4 gap-x-8" :
                                        "flex flex-col space-y-4 md:space-y-0 md:grid grid-cols-4 gap-y-4 gap-x-8"
                }>
                    {currentSubs.map((nav, index) => (
                        <div key={index}
                                onClick={() => {
                                    navigate(nav.path)
                                }}
                             className={'shadow-xxl p-6 rounded-4xl flex flex-col space-y-6 items-center flex-1 transition duration-300 ease-in-out hover:shadow-2xl hover:bg-gray-100 cursor-pointer hover:scale-105'}>
                            <div className={'text-lg font-bold text-secondary text-center'}>
                                {intl.formatMessage({id: nav.name})} 
                            </div>
                            <i className={nav.icon + " text-green-600 text-5xl"}></i>
                            {renderOptions(nav.options)}
                            <button className={'bg-secondary py-2 px-6 rounded-full text-white'} onClick={() => {
                                navigate(nav.path)
                            }}>
                                {intl.formatMessage({id: nav.button_name})}
                            </button>
                        </div>
                    ))}
                </div>

                <div className={'flex items-center justify-center'}>
                    <button className={'bg-secondary py-2 px-6 rounded-full text-white mt-6'} onClick={() => {
                        toggle(pagesNav[0].subs)
                    }}>
                        <i className='fa-solid fa-arrow-left mr-4'></i> {intl.formatMessage({id: 'back'})}
                    </button>
                </div>
            </div>
        )

        return render;
    }

    const renderAdminNav = () => {
        let render = (
            <React.Fragment>
                {pagesCompanyNav.map((nav, index) => (
                    <div 
                        onClick={() => {
                            toggle(nav.subs)
                        }} 
                        key={index}
                         className={'shadow-xxl p-6 rounded-4xl flex flex-col space-y-4 items-center justify-between flex-1 transition duration-300 ease-in-out hover:shadow-2xl hover:bg-gray-100 cursor-pointer hover:scale-105'}>
                        <div className={'text-lg font-bold text-secondary text-center'}>
                            {intl.formatMessage({id: nav.name})}
                        </div>
                        <i className={nav.icon + " text-green-600 text-5xl"}></i>
                        {renderOptions(nav.options)}
                        <button className={'bg-secondary py-2 px-6 rounded-full text-white'} onClick={() => {
                            toggle(nav.subs)
                        }}>
                            {intl.formatMessage({id: 'acceder'})} 
                        </button>
                    </div>
                ))}
            </React.Fragment>
        )

        return render;
    }

    const toggle = (subs) => {
        setCurrentSubs(subs)

        if (!showSub) {
            setTimeout(function() {
                setShowSub(!showSub)
            }, 200)
        } else {
            setShowSub(!showSub)
        }

        if (!showMain) {
            setTimeout(function() {
                setShowMain(!showMain)
            }, 200)
        } else {
            setShowMain(!showMain)
        }

    }

    const [activeIndex, setActiveIndex] = useState(null); 

    const toggleBackground = (index, subs) => {
        setActiveIndex(index); 
        toggle(subs); 
      };

    return (
        <div className={'p-6 min-h-screen_custom'}>
            <div className={'flex flex-col flex-grow'}>
                <div className="flex space-x-4 justify-center items-center relative mb-6">
                    <h1 className="xl:w-1/3 py-3 px-6 rounded-full shadow-xxl font-sans font-semibold text-secondary xl:text-lg text-center">
                        {intl.formatMessage({id: 'what.you.want.to.do'})} 
                    </h1>
                </div>

                <Slide direction="left" in={showSub} mountOnEnter unmountOnExit>
                    {renderSubs()}
                </Slide>
                <Slide direction="right" in={showMain} mountOnEnter unmountOnExit>
                    <div className={"flex flex-col space-y-6 md:flex-row md:space-x-6 md:space-y-0"}>
                    {pagesNav.map((nav, index) => (
                        <div 
                            key={index} 
                            onClick={() => toggleBackground(index, nav.subs)} 
                            className={`shadow-xxl p-6 rounded-4xl cursor-pointer flex flex-col space-y-9 3xl:p-10 items-center justify-between flex-1 
                                ${activeIndex === index ? 'bg-blue-100' : 'bg-white'} transition duration-300 ease-in-out hover:shadow-2xl hover:bg-gray-100 cursor-pointer hover:scale-105`}
                        >
                            <div className={'text-lg font-bold text-secondary text-center'}>
                                {intl.formatMessage({id: nav.name})}
                            </div>
                            <i className={ nav.icon + " text-green-600 text-5xl"}></i>
                            {renderOptions(nav.options)}
                            <button className={'bg-secondary py-2 px-6 rounded-full text-white'} onClick={() => {
                                toggle(nav.subs)
                            }}>
                                {intl.formatMessage({id: 'acceder'})}
                            </button>
                        </div>
                    ))}
                    {contact?.is_admin ? renderAdminNav() : null}
                    {/* {pagesAccNav.map((nav, index) => (
                        <div key={index} className={'shadow-xxl p-6 rounded-4xl flex flex-col space-y-4 items-center justify-between flex-1'}>
                            <div className={'text-lg font-bold text-secondary text-center'}>
                                {intl.formatMessage({id: nav.name})}
                            </div>
                            <i className={ nav.icon + " text-green-600 text-5xl"}></i>
                            {renderOptions(nav.options)}
                            <button className={'bg-secondary py-2 px-6 rounded-full text-white'} onClick={() => {
                                toggle(nav.subs)
                            }}>
                                {intl.formatMessage({id: 'enter'})}
                            </button>
                        </div>
                    ))} */}
                </div>
                </Slide>
            </div>
        </div>
    )
}

export default injectIntl(Dashboard)
