import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {IntlProvider} from "react-intl"
import translations from "../../translations"
import {getLocale} from "../../utils/compute"
import {getCurrentUser} from "../../redux/actions/Auth"
import {changeLocale} from "../../redux/actions/Common";


const AppIntlProvider = ({children}) => {
    const dispatch = useDispatch()

    const {token, user} = useSelector(({auth}) => auth)
    const {talent} = useSelector(({talent}) => talent)
    const {contact} = useSelector(({company}) => company)
    const {locale} = useSelector(({common}) => common)

    useEffect(() => {
        const userLang = user?.language_code

        if (userLang) {
            dispatch(changeLocale(getLocale(userLang)))
        }
    }, [user])

    useEffect(() => {
        if (user?.type == 'talent' && talent?.preferred_language_code !== user?.language_code) {
            dispatch(getCurrentUser(token))
        }
        if (user?.type == 'company' && contact?.preferred_language_code !== user?.language_code) {
            dispatch(getCurrentUser(token))
        }
    }, [talent, contact])

    return (
        <IntlProvider locale={locale.short} messages={translations.messages[locale.specific]}>
            {children}
        </IntlProvider>
    )
}

export default AppIntlProvider
