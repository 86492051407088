import React, {lazy} from "react";
import {Route, Routes,} from "react-router-dom";

/*Talent Pages*/
import Dashboard from "../views/Talent/Dashboard";
import DashboardCompany from "../views/Company/Dashboard";
import ProfilPersonalData from "../views/Talent/ProfilPersonalData";

const PersonalData = lazy(() => import('../views/Talent/PersonalData'))
const Avatar = lazy(() => import('../views/Talent/Avatar'))
const Profil = lazy(() => import('../views/Talent/MyProfil'))
const Express = lazy(() => import('../views/Talent/Express'))
const Semi = lazy(() => import('../views/Talent/Semi'))
const SemiAuto = lazy(() => import('../views/Talent/Semiauto'))
const Mdp = lazy(() => import('../views/Talent/Mdp'))
const Social = lazy(() => import('../views/Talent/Social'))
const Education = lazy(() => import('../views/Talent/Education'))
const Experience = lazy(() => import('../views/Talent/Experience'))
const Certification = lazy(() => import('../views/Talent/Certification'))
const Language = lazy(() => import('../views/Talent/Languages'))
const Hobby = lazy(() => import('../views/Talent/Hobby'))
const Preferences = lazy(() => import('../views/Talent/Preferences'))
const Presentation = lazy(() => import('../views/Talent/Presentation'))
const Preview = lazy(() => import('../views/Talent/Preview'))
const Documents = lazy(() => import('../views/Talent/Documents'))
const Salary = lazy(() => import('../views/Talent/Salary'))
const TalentEntretien = lazy(() => import('../views/Talent/Entretien'))
const TalentAutoProfile = lazy(() => import('../views/Talent/AutoProfile'))
const TalentCandidature = lazy(() => import('../views/Talent/Candidature'))
const TalentUploadCV = lazy(() => import('../views/Talent/UploadCV'))
const TalentUploadLinkedin = lazy(() => import('../views/Talent/UploadLinkedin'))
const TalentUploadChoose = lazy(() => import('../views/Talent/UploadChoose'))
const TalentNeed = lazy(() => import('../views/Talent/Need'))
const TalentWhatNext = lazy(() => import('../views/Talent/WhatNext'))
const TalentHow = lazy(() => import('../views/Talent/HowWorks'))
const TalentWaiting = lazy(() => import('../views/Talent/Waiting'))
const TalentReferTalent = lazy(() => import('../views/Talent/ReferTalent'))
const TalentReferCompany = lazy(() => import('../views/Talent/ReferCompany'))
const TalentPortageEmployed = lazy(() => import('../views/Talent/PortageEmployed'))
const TalentPortageIndependant = lazy(() => import('../views/Talent/PortageIndependant'))
const TalentTimesheet = lazy(() => import('../views/Talent/Timesheet'))
const TalentPayslip = lazy(() => import('../views/Talent/Payslip'))
const TalentPayslipSingle = lazy(() => import('../views/Talent/Payslip/single'))
const TalentPayslipExplain = lazy(() => import('../views/Talent/Payslip/explain'))
const TalentExpenseReport = lazy(() => import('../views/Talent/ExpenseReport'))
const TalentHoliday = lazy(() => import('../views/Talent/Holiday'))
const TalentBonus = lazy(() => import('../views/Talent/Bonus'))
const TalentContract = lazy(() => import('../views/Talent/Contract'))
const TalentOnboarding = lazy(() => import('../views/Talent/Onboarding'))

/*Search Job Offers*/
const SearchJobOffer = lazy(() => import('../views/Search/JobOffer'))
const SearchJobOfferCompare = lazy(() => import('../views/Search/JobOffer/compare'))
const Candidature = lazy(() => import('../views/Search/JobOffer/candidature'))
const FavoriteJobOffer = lazy(() => import('../views/Search/JobOffer/favorite'))

/*Search Talents*/
const SearchTalent = lazy(() => import('../views/Search/Talent'))
const FavoriteTalent = lazy(() => import('../views/Search/Talent/favorite'))
const SearchTalentCompare = lazy(() => import('../views/Search/Talent/compare'))
const Proposals = lazy(() => import('../views/Search/Talent/proposals'))


/*Company pages*/
const ExpressCompany = lazy(() => import('../views/Company/Express'))
const CompanyPersonalData = lazy(() => import('../views/Company/ProfilPersonalData'))
const CompanyProfil = lazy(() => import('../views/Company/MyProfil'))
const InfoCompany = lazy(() => import('../views/Company/InfoCompany'))
const AboutCompany = lazy(() => import('../views/Company/MyCompany'))
const AvatarCompany = lazy(() => import('../views/Company/Avatar'))
const IconCompany = lazy(() => import('../views/Company/Icon'))
const SocietyCompany = lazy(() => import('../views/Company/Society'))
const ConfidentielCompany = lazy(() => import('../views/Company/Confidentiel'))
const MdpCompany = lazy(() => import('../views/Company/Mdp'))
const ContactCompany = lazy(() => import('../views/Company/Contact'))
const PreferencesCompany = lazy(() => import('../views/Company/Preferences'))
const DocumentCompany = lazy(() => import('../views/Company/Documents'))
const ValueCompany = lazy(() => import('../views/Company/Value'))
const JobOffersCompany = lazy(() => import('../views/Company/JobOffers'))
const JobOfferDetail = lazy(() => import('../views/Company/JobOffers/Detail'))
const JobOfferFlow = lazy(() => import('../views/Company/JobOffers/Flow'))
const JobOfferAuto = lazy(() => import('../views/Company/JobOffers/Auto'))
const JobOfferAutoFirst = lazy(() => import('../views/Company/JobOffers/Auto_first'))
const JobOfferFiche = lazy(() => import('../views/Company/JobOffers/Fiche'))
const JobOfferMatch = lazy(() => import('../views/Company/JobOffers/Match'))
const CompanyCandidature = lazy(() => import('../views/Company/JobOffers/Candidature'))
const CompanyCandidatureSingle = lazy(() => import('../views/Company/JobOffers/Single'))
const CompanyCollab = lazy(() => import('../views/Company/Collab'))
const GuideTemplate = lazy(() => import('../views/Company/JobOffers/GuideTemplate'))
const CompanyHow = lazy(() => import('../views/Company/HowWorks'))
const CompanyNeed = lazy(() => import('../views/Company/Need'))
const CompanyGroup = lazy(() => import('../views/Company/Groups'))
const CompanyTeam = lazy(() => import('../views/Company/Team'))
const CompanyTimesheet = lazy(() => import('../views/Company/Team/timesheet'))
const CompanyExpenseReport = lazy(() => import('../views/Company/Team/expense_report'))
const CompanyInvoice = lazy(() => import('../views/Company/Invoice'))
const CompanyInvoiceSingle = lazy(() => import('../views/Company/Invoice/single'))
const CompanyPortageEmployed = lazy(() => import('../views/Company/PortageEmployed'))
const CompanyPortageIndependant = lazy(() => import('../views/Company/PortageIndependant'))
const CompanyReferTalent = lazy(() => import('../views/Company/ReferTalent'))
const CompanyReferCompany = lazy(() => import('../views/Company/ReferCompany'))
const CompanyProposition = lazy(() => import('../views/Company/Contracting/proposition'))
const CompanyHolidays = lazy(() => import('../views/Company/Team/holidays'))
const CompanyBonus = lazy(() => import('../views/Company/Team/bonus'))
const CompanyContract = lazy(() => import('../views/Company/Team/contract'))

const Visio = lazy(() => import('../views/Visio'))

/*Common pages*/
const NotFound = lazy(() => import('../views/NotFound'))
const Forbidden = lazy(() => import('../views/Forbidden'))
const Disconnect = lazy(() => import('../views/Auth/Disconnect'))

const AppRoutes = () => {
    return (
        <Routes>

            {/* TALENT ROUTES   */}
            <Route path="talent/dashboard" element={<Dashboard/>}/>
            <Route path="talent/personal-data" element={<PersonalData/>}/>
            <Route path="talent/avatar" element={<Avatar/>}/>
            <Route path="talent/profil" element={<Profil/>}/>
            <Route path="talent/profil-personal-data" element={<ProfilPersonalData/>}/>
            <Route path="talent/express" element={<Express/>}/>
            <Route path="talent/need" element={<TalentNeed/>}/>
            <Route path="talent/what_next" element={<TalentWhatNext/>}/>
            <Route path="talent/how_works" element={<TalentHow/>}/>
            <Route path="talent/waiting" element={<TalentWaiting/>}/>
            <Route path="talent/semi" element={<Semi/>}/>
            <Route path="talent/semiauto" element={<SemiAuto/>}/>
            <Route path="talent/choose_upload" element={<TalentUploadChoose/>}/>
            <Route path="talent/uploadcv" element={<TalentUploadCV/>}/>
            <Route path="talent/upload_linkedin" element={<TalentUploadLinkedin/>}/>
            <Route path="talent/mdp" element={<Mdp/>}/>
            <Route path="talent/social" element={<Social/>}/>
            <Route path="talent/education" element={<Education/>}/>
            <Route path="talent/experience" element={<Experience/>}/>
            <Route path="talent/certification" element={<Certification/>}/>
            <Route path="talent/languages" element={<Language/>}/>
            <Route path="talent/hobby" element={<Hobby/>}/>
            <Route path="talent/preference" element={<Preferences/>}/>
            <Route path="talent/presentation" element={<Presentation/>}/>
            <Route path="talent/preview" element={<Preview/>}/>
            <Route path="talent/documents" element={<Documents/>}/>
            <Route path="talent/salary" element={<Salary/>}/>
            <Route path="talent/auto-profile" element={<TalentAutoProfile/>}/>
            <Route path="talent/refer_talent" element={<TalentReferTalent/>}/>
            <Route path="talent/refer_entreprise" element={<TalentReferCompany/>}/>
            <Route path="talent/port_salary" element={<TalentPortageEmployed/>}/>
            <Route path="talent/port_independant" element={<TalentPortageIndependant/>}/>
            <Route path="talent/timesheet" element={<TalentTimesheet/>}/>
            <Route path="talent/renumeration" element={<TalentPayslip/>}/>
            <Route path="talent/renumeration/:id" element={<TalentPayslipSingle/>}/>
            <Route path="talent/renumeration/explain/:id" element={<TalentPayslipExplain/>}/>
            <Route path="talent/expense_report" element={<TalentExpenseReport />}/>
            <Route path="talent/holiday" element={<TalentHoliday />}/>
            <Route path="talent/bonus" element={<TalentBonus />}/>
            <Route path="talent/my_contract" element={<TalentContract />}/>
            <Route path="talent/onboarding" element={<TalentOnboarding />}/>

            <Route path="talent/search/job_offers" element={<SearchJobOffer/>}/>
            <Route path="talent/search/job_offers/compare" element={<SearchJobOfferCompare/>}/>
            <Route path="talent/candidature" element={<TalentCandidature/>}/>
            <Route path="talent/interviews" element={<TalentEntretien/>}/>
            <Route path="talent/search/job_offers/favorites" element={<FavoriteJobOffer/>}/>

            {/* COMPANY ROUTES   */}
            <Route path="company/express" element={<ExpressCompany/>}/>
            <Route path="company/dashboard" element={<DashboardCompany/>}/>
            <Route path="company/avatar" element={<AvatarCompany/>}/>
            <Route path="company/profil" element={<CompanyProfil/>}/>
            <Route path="company/info" element={<InfoCompany/>}/>
            <Route path="company/about" element={<AboutCompany/>}/>
            <Route path="/company/profil-personal-data" element={<CompanyPersonalData/>}/>
            <Route path="company/icon" element={<IconCompany/>}/>
            <Route path="company/mdp" element={<MdpCompany/>}/>
            <Route path="company/how_works" element={<CompanyHow/>}/>
            <Route path="company/society" element={<SocietyCompany/>}/>
            <Route path="company/contact" element={<ContactCompany/>}/>
            <Route path="company/confidentiel" element={<ConfidentielCompany/>}/>
            <Route path="company/preferences" element={<PreferencesCompany/>}/>
            <Route path="company/document" element={<DocumentCompany/>}/>
            <Route path="company/values" element={<ValueCompany/>}/>
            <Route path="company/job_offer/list" element={<JobOffersCompany/>}/>
            <Route path="company/job_offer/view/:id" element={<JobOfferDetail/>}/>
            <Route path="company/job_offer/fiche/:id" element={<JobOfferFiche/>}/>
            <Route path="company/job_offer/guide/:id" element={<GuideTemplate/>}/>
            <Route path="company/job_offer/match/:id" element={<JobOfferMatch/>}/>
            <Route path="company/job_offer/auto" element={<JobOfferAuto />}/>
            <Route path="company/job_offer/auto_first" element={<JobOfferAutoFirst />}/>
            <Route path="company/job_offer/flow/:id" element={<JobOfferFlow/>}/>
            <Route path="company/job_offer/search/talents/favorites" element={<FavoriteTalent/>}/>
            <Route path="company/job_offer/candidature" element={<CompanyCandidature/>}/>
            <Route path="company/job_offer/candidature/single" element={<CompanyCandidatureSingle/>}/>
            <Route path="company/search/talents" element={<SearchTalent/>}/>
            <Route path="company/search/talents/compare" element={<SearchTalentCompare/>}/>
            <Route path="company/proposals" element={<Proposals/>}/>
            <Route path="company/collab" element={<CompanyCollab/>}/>
            <Route path="company/group" element={<CompanyGroup/>}/>
            <Route path="company/my_team" element={<CompanyTeam/>}/>
            <Route path="company/my_team/timesheet/:id" element={<CompanyTimesheet />}/>
            <Route path="company/my_team/expense_report/:id" element={<CompanyExpenseReport />}/>
            <Route path="company/my_team/holidays/:id" element={<CompanyHolidays />}/>
            <Route path="company/my_team/bonus/:id" element={<CompanyBonus />}/>
            <Route path="company/my_team/contract/:id" element={<CompanyContract />}/>
            <Route path="company/invoices" element={<CompanyInvoice/>}/>
            <Route path="company/invoices/:id" element={<CompanyInvoiceSingle/>}/>
            <Route path="company/need" element={<CompanyNeed/>}/>
            <Route path="company/port_talent_employee" element={<CompanyPortageEmployed/>}/>
            <Route path="company/port_talent_prestataire" element={<CompanyPortageIndependant/>}/>
            <Route path="company/refer_talent" element={<CompanyReferTalent/>}/>
            <Route path="company/refer_entreprise" element={<CompanyReferCompany/>}/>
            <Route path="company/proposition" element={<CompanyProposition/>}/>

            <Route path="visio/:id" element={<Visio/>}/>

            <Route path="forbidden" element={<Forbidden/>}/>

            <Route path="disconnect" element={<Disconnect/>}/>

            <Route path="*" element={<NotFound/>}/>
        </Routes>
    )
};

export default AppRoutes