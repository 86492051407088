// Import the redux-saga/effects
import {call, put, takeEvery} from 'redux-saga/effects'

// Import all action and api's
import {UPDATE_EDU_EXP_CERT,UPDATE_SKILL_LANG_HOBBY} from '../action-types'

// Import all api's
import {updateEduExpCertRequest,updateSkillLangHobbyRequest} from '../api/EduExpCert'

import {updateEduExpCertSuccess,updateSkillLangHobbySuccess} from '../actions/EduExpCert'

import {fetchError, fetchExpired, fetchStart, fetchSuccess} from '../actions/Common'

function* updateEduExpCert({token, apiValues}) {
    try {
        yield put(fetchStart())
        const response = yield call(updateEduExpCertRequest, token, apiValues)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(updateEduExpCertSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* updateSkillLangHobby({token, apiValues}) {
    try {
        yield put(fetchStart())
        const response = yield call(updateSkillLangHobbyRequest, token, apiValues)
        if (response.status === 200) {
            yield put(updateSkillLangHobbySuccess(response.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

// Export the saga (todo-saga)
export default function* todoSaga() {
    yield takeEvery(UPDATE_EDU_EXP_CERT, updateEduExpCert)
    yield takeEvery(UPDATE_SKILL_LANG_HOBBY, updateSkillLangHobby)
}