import {
    FETCH_ERROR,
    FETCH_ERROR_CUSTOM,
    FETCH_EXPIRED,
    FETCH_START,
    FETCH_START_WITHOUT_LOADING,
    FETCH_SUCCESS,
    HIDE_MESSAGE,
    HIDE_MESSAGE_UNAUTH,
    POST_SUCCESS,
    REQUEST_SUCCESS,
    SET_COMMON_STATE,
    SHOW_MESSAGE,
    SHOW_UNAUTH,
    CHANGE_LOCALE
} from '../action-types'

const initialState = {
    error: "",
    unauth: false,
    loading: false,
    message: '',
    show_success: false,
    request_success: false,
    expired: false,
    step: 1,
    scrollToTop: false,
    locale: {
        short: 'fr',
        specific: 'frFR'
    }
}

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_START: {
            return {...state, error: '', message: '', loading: true, show_success: false, unauth: false}
        }
        case FETCH_START_WITHOUT_LOADING: {
            return {...state, error: '', message: '', loading: false, show_success: false}
        }
        case FETCH_EXPIRED: {
            return {...state, error: '', message: '', expired: true, loading: false}
        }
        case FETCH_SUCCESS: {
            return {...state, error: '', message: '', loading: false}
        }
        case POST_SUCCESS: {
            return {...state, error: '', message: '', loading: false, show_success: true}
        }
        case REQUEST_SUCCESS: {
            return {...state, error: '', message: '', loading: false, request_success: action.data}
        }
        case SHOW_MESSAGE: {
            return {...state, error: '', message: action.payload, loading: false}
        }
        case FETCH_ERROR: {
            return {...state, loading: false, error: action.payload, message: ''}
        }
        case FETCH_ERROR_CUSTOM: {
            return {...state, loading: false, error: '', message: action.message}
        }
        case HIDE_MESSAGE: {
            return {...state, loading: false, error: '', message: '', expired: false, show_success: false}
        }
        case HIDE_MESSAGE_UNAUTH: {
            return {...state, loading: false, unauth: false, message: '', expired: false}
        }
        case SHOW_UNAUTH: {
            return {...state, loading: false, unauth: true, message: '', expired: false}
        }
        case CHANGE_LOCALE: {
            return {...state, locale: action.locale}
        }
        case SET_COMMON_STATE: {
            return {
                ...state,
                ...action?.payload
            }
        }
        // case HIDE_FORM_CONFORMATION: {
        //     return { ...state, show_success: false, step: action.step }
        // }
        default:
            return state
    }
}