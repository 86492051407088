import {axiosInstance} from '../../axios/axios'

export const getAllUploadFilesRequest = async (token) =>
    await axiosInstance.get('/api/document/all', {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const uploadFileRequest = async (token, values) =>
    await axiosInstance.post('/api/document/upload', values, {
        headers: {
            Authorization: 'Bearer ' + token,
            "Content-Type": "multipart/form-data",
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const uploadFileExpenseReportRequest = async (token, values) =>
    await axiosInstance.post('/api/document/upload/expense_report', values, {
        headers: {
            Authorization: 'Bearer ' + token,
            "Content-Type": "multipart/form-data",
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })



export const deleteFileRequest = async (token, id) =>
    await axiosInstance.get(`/api/document/delete/${id}`, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })