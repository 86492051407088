// Import the redux-saga/effects
import {call, put, takeEvery} from 'redux-saga/effects'

// Import all action and api's
import {DELETE_SEARCH, GET_SEARCH, SAVE_SEARCH, SEARCH_ACTION, SEARCH_MATCH} from '../action-types'

// Import all api's
import {deleteSearchRequest, getSearchRequest, saveSearchRequest, searchActionRequest, searchMatchRequest} from '../api/Search'

import {deleteSearchSuccess, getSearchSuccess, saveSearchSuccess, searchActionSuccess, toggleShowCVWarning, talentALreadyApplied, searchMatchSuccess} from '../actions/Search'

import {fetchError, fetchExpired, fetchStart, fetchSuccess} from '../actions/Common'

function* getSearch({token}) {
    try {
        //yield put(fetchStart())
        const response = yield call(getSearchRequest, token)
        if (response.status === 200) {
            yield put(getSearchSuccess(response?.data))
            //yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* saveSearch({token, form}) {
    try {
        yield put(fetchStart())
        const response = yield call(saveSearchRequest, token, form)
        if (response.status === 200) {
            yield put(saveSearchSuccess(response?.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* deleteSearch({token, id}) {
    try {
        yield put(fetchStart())
        const response = yield call(deleteSearchRequest, token, id)
        if (response.status === 200) {
            yield put(deleteSearchSuccess(response?.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* searchAction({token, form}) {
    try {
        yield put(fetchStart())
        const response = yield call(searchActionRequest, token, form)
        if (response.status === 200) {
            yield put(searchActionSuccess(response?.data))
            yield put(fetchSuccess())
        }
        if (response.status === 403) {
            yield put(toggleShowCVWarning())
            //talent must upload CV
            yield put(fetchSuccess())
        }
        if (response.status === 409) {
            //talent already applied
            yield put(talentALreadyApplied())
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* searchMatch({token, id}) {
    try {
        yield put(fetchStart())
        const response = yield call(searchMatchRequest, token, id)
        if (response.status === 200) {
            yield put(searchMatchSuccess(response?.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

// Export the saga (todo-saga)
export default function* todoSaga() {
    yield takeEvery(GET_SEARCH, getSearch)
    yield takeEvery(SAVE_SEARCH, saveSearch)
    yield takeEvery(DELETE_SEARCH, deleteSearch)
    yield takeEvery(SEARCH_ACTION, searchAction)
    yield takeEvery(SEARCH_MATCH, searchMatch)
}