import {spawn} from 'redux-saga/effects'

// Sagas
import authSaga from './Auth'
import listSaga from './List'
import talentSaga from './Talent'
import fileSaga from './File'
import refSaga from './Ref'
import eduExpCertSaga from './EduExpCert'
import jobOffer from './JobOffer'
import search from './Search'
import companySaga from './Company'
import jobOfferApplicationSaga from "./JobOfferApplication";
import chatgptSaga from "./Chatgpt";
import portageSaga from "./Portage";

// Export the root saga
export default function* rootSaga() {
    yield spawn(authSaga)
    yield spawn(listSaga)
    yield spawn(talentSaga)
    yield spawn(fileSaga)
    yield spawn(refSaga)
    yield spawn(eduExpCertSaga)
    yield spawn(jobOffer)
    yield spawn(search)
    yield spawn(companySaga)
    yield spawn(jobOfferApplicationSaga)
    yield spawn(chatgptSaga)
    yield spawn(portageSaga)
}