import {
    DELETE_FILE_SUCCESS,
    GET_ALL_UPLOAD_FILES_SUCCESS,
    SET_FILE_STATE,
    UPLOAD_FILE_SUCCESS,
    UPLOAD_FILE_EXPENSE_REPORT_SUCCESS,
    UPLOAD_FILE_CONTRACTOR_INVOICE_SUCCESS
} from '../action-types'

const INIT_STATE = {
    documents: null,
    expense_report_doc: {},
    contractor_invoice_doc: {},
    file_request_success: 0,
    uploaded_file: null
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_UPLOAD_FILES_SUCCESS: {
            return {...state, documents: action?.data?.documents}
        }

        case UPLOAD_FILE_SUCCESS: {
            return {
                ...state,
                documents: action?.data?.documents,
                file_request_success: state?.file_request_success + 1,
                uploaded_file: action?.data?.document,
            }
        }

        case UPLOAD_FILE_EXPENSE_REPORT_SUCCESS: {
            return {
                ...state,
                expense_report_doc: action?.data?.expense_report_doc,
            }
        }

        case UPLOAD_FILE_CONTRACTOR_INVOICE_SUCCESS: {
            return {
                ...state,
                contractor_invoice_doc: action?.data?.expense_report_doc,
            }
        }

        case DELETE_FILE_SUCCESS: {
            return {
                ...state,
                documents: action?.data?.documents,
                file_request_success: state?.file_request_success + 1,
                uploaded_file: null,
            }
        }

        case SET_FILE_STATE: {
            return {
                ...state,
                ...action?.payload
            }
        }

        default:
            return state
    }
}
