import React from 'react'
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { styled } from '@mui/material/styles';
import ForumIcon from '@mui/icons-material/Forum';
import { injectIntl } from "react-intl";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const CreateTicket = React.memo(({ intl }) => {
  const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
    position: 'fixed',
    bottom: theme.spacing(1),
    right: theme.spacing(4),
  }));

  const actions = [
    { label1: intl.formatMessage({ id: 'support.label1' }), label2: intl.formatMessage({ id: 'support.label2' }), button: intl.formatMessage({ id: 'support.button' }) },
  ];

  return (
    <Box>
      <StyledSpeedDial
        ariaLabel={intl.formatMessage({ id: 'support.label1' })}
        icon={
          <Box>
            <ForumIcon sx={{ color: 'white', fontSize: '2rem' }} />
          </Box>
        }
        direction="left"
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.label1}
            icon={
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  backgroundColor: 'white',
                  padding: '10px',
                  borderRadius: '1rem',
                }}
              >
                <Typography
                  sx={{
                    paddingBottom: '10px',
                    textAlign: 'center',
                    color: '#333',
                    textTransform: 'none'
                  }}
                >
                  {action.label1}
                  <br/>
                  {action.label2} 
                </Typography>
                <a
                  target="_blank"
                  href="https://talenteum.freshdesk.com/support/tickets/new"
                  className="bg-secondary py-2 px-6 rounded-full text-white"
                  style={{
                    marginTop: '5px',
                    textDecoration: 'none',
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textTransform: 'none'
                  }}
                >
                  {action.button} 
                </a>
              </Box>
            }
            sx={{
              width: 320,
              height: 'auto',
              borderRadius: '2rem',
              padding: '20px',
              backgroundColor: 'white',
              '& .MuiSpeedDialAction-fab': {
                width: '100%',
                height: 60,
                borderRadius: '2rem',
              },
              '& .MuiSpeedDialAction-staticTooltipLabel': {
                fontSize: '1.2rem',
              },
              '&:hover': {
                backgroundColor: 'white',
              },
              position: 'relative',
              right: 0,
            }}
          />
        ))}
      </StyledSpeedDial>
    </Box>
  );
});

export default injectIntl(CreateTicket);
