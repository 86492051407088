import React, {useEffect, useState} from 'react'
import {injectIntl} from "react-intl"
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
import {getCurrentUser, registerResendEmail, userSignIn} from "../../redux/actions/Auth";
import {useDispatch, useSelector} from "react-redux";
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import {useFormik} from 'formik'
import * as yup from 'yup'
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {capitalizeFirstLetter} from "../../utils/format";
import {changeLocale} from "../../redux/actions/Common";
import {getLocale} from "../../utils/compute";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const Login = ({intl}) => {
    const dispatch = useDispatch()
    let navigate = useNavigate()
    let location = useLocation()
    let fromTalent = '/talent/dashboard'
    let fromCompany = '/company/dashboard'

    //cannot get this to work correclty
    let pathBefore = location.state?.from?.pathname

    let {first} = useParams()

    const {token, user, register_success, resend_email_success} = useSelector(({auth}) => auth)
    const {message, loading, locale} = useSelector(({common}) => common)
    const {language} = useSelector(({list}) => list)

    const [showAlert, setShowAlert] = useState(false)
    const [resendClicked, setResendClicked] = useState(false)
    const [alertMsg, setAlertMsg] = useState('')
    const [formTalent, setFormTalent] = useState({
        email:  new URLSearchParams(window.location.search)?.get('email') || '',
        password: ''
    })
    const [showPassword, setShowPassword] = useState(false)


    const toogleShowPassword = () => {
        setShowPassword(!showPassword)
    }


    const validationSchema = yup.object({
        email: yup
            .string()
            .required(intl.formatMessage({id: 'this.field.is.required'})),
        password: yup
            .string()
            .required(intl.formatMessage({id: 'this.field.is.required'})),
    })

    const formik = useFormik({
        initialValues: formTalent,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            formTalent.email = formTalent?.email?.trim()
            dispatch(userSignIn(formTalent))
        },
    })

    useEffect(() => {
        if (token && !user) {
            dispatch(getCurrentUser(token))
        }
    }, [token, user])

    useEffect(() => {
        if (token && user?.type) {
            if (user.type == 'talent') {
                navigate(fromTalent, {replace: true})
            }
            if (user.type == 'company') {
                navigate(fromCompany, {replace: true})
            }
        }
    }, [user, token, fromTalent, fromCompany, navigate])

    useEffect(() => {
        if (message === 'incorrect_credentials') {
            setShowAlert(true)
            setAlertMsg(intl.formatMessage({id: 'invalid.credentials'}))
        }
        if (message === 'email_not_exists') {
            setShowAlert(true)
            setAlertMsg(intl.formatMessage({id: 'no.email'}))
        }
        if (message === 'no_email') {
            setShowAlert(true)
            setAlertMsg(intl.formatMessage({id: 'no.email'}))
        }

        if (message === 'account_blocked') {
            setShowAlert(true)
            setAlertMsg(intl.formatMessage({id: 'account.blocked'}))
        }

        if (message === 'error') {
            setShowAlert(true)
            setAlertMsg(intl.formatMessage({id: 'error.generic'}))
        }
    }, [message])

    useEffect(() => {
        const registeredEmail = register_success?.user?.email
        if (registeredEmail) {
            setFormTalent({
                ...formTalent,
                email: registeredEmail
            })
        }
    }, [register_success])

    useEffect(() => {
        if (resendClicked) {
            navigate('/login/new')
        }
    }, [resend_email_success])



    const handleTextChange = (value, field) => {
        setFormTalent({
            ...formTalent,
            [field]: value
        })
    }

    const handleResendEmail = () => {
        dispatch(registerResendEmail({
            email: formTalent?.email,
            locale: locale?.short
        },intl))
        setResendClicked(true)
    }

    return (
        <div className="login-container">
            <header className="py-2 flex flex-col space-y-5 justify-center items-center">
                <img src={require('./../../assets/images/logo.png')} className="w-96" alt="Logo de Talenteum"/>

                <h1 className="py-3 px-6 rounded-full shadow-xxl font-semibold text-green-600 text-xl text-center">
                    {intl.formatMessage({id: 'login.message'})}
                </h1>
            </header>

            <main className="my-8 flex justify-center">
                <form
                    className="xs:w-11/12 sm:w-7/12 grid lg:grid-cols-2 md:grid-cols-1 gap-x-10 gap-y-5 place-content-center xl:grid"
                    onSubmit={formik.handleSubmit}>

                    <section className="shadow-xxl rounded-4xl hidden md:block">
                        <img src={require('./../../assets/images/login.jpg')} alt="Image de login"
                             className="rounded-4xl responsive_height"/>
                    </section>


                    <section className="shadow-xxl rounded-4xl flex justify-center flex-col p-8">
                        {
                            first && first == 'new' && !loading ?
                                <MuiAlert severity="success" className="mb-4">
                                    {intl.formatMessage({id: 'register.email.msg'})}
                                    <br />
                                    {intl.formatMessage({id: 'enter.below.continue'})}
                                </MuiAlert>
                                :
                                null
                        }

                        <section className="w-full flex-1 mt-4 ">
                            <TextField
                                label={intl.formatMessage({id: 'email'})}
                                className={'w-full'}
                                //value={formTalent?.email}
                                defaultValue={formik.values.email || ''}
                                id="formatted-numberformat-input"
                                onChange={(e) => handleTextChange(e?.target?.value, 'email')}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">
                                        <i className={'fa-regular fa-envelope text-secondary'}></i>
                                    </InputAdornment>,
                                }}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </section>

                        {
                            !first || first == 'new' ?
                                <section className="w-full flex-1 mt-4 ">
                                    <TextField
                                        label={intl.formatMessage({id: (first && first == 'new' && !loading) ? 'validation.code' : 'password'})}
                                        className={'w-full'}
                                        //value={formTalent?.password}
                                        value={formik.values.password || ''}
                                        type={showPassword ? 'text' : 'password'}
                                        id="formatted-numberformat-input"
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">
                                                <i className={'fa fa-lock text-secondary'}></i>
                                            </InputAdornment>,
                                            endAdornment :
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={toogleShowPassword}
                                                        edge="end">
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                        }}
                                        onChange={(e) => handleTextChange(e?.target?.value, 'password')}
                                        error={formik.touched.password && Boolean(formik.errors.password)}
                                        helperText={formik.touched.password && formik.errors.password}
                                    />
                                </section>
                                :
                                null
                        }

                        <div className="flex-1 flex-col justify-center items-end space-y-4 mt-4">

                            {
                                !first || first == 'new' ?
                                    <button type="submit"
                                            className="px-8 py-2 bg-secondary rounded-full text-white w-full"
                                        // onClick={() => {
                                        //     dispatch(userSignIn(formTalent))
                                        // }}
                                            disabled={loading}
                                    >
                                        {intl.formatMessage({id: (first && first == 'new' && !loading) ? 'validate' : 'login'})}
                                    </button>
                                    :
                                    null
                            }

                            {
                                !first || first == 'new' ?
                                    <button type="button"
                                            className="px-8 py-2 text-secondary border-2 border-secondary rounded-full flex items-center justify-center w-full"
                                        onClick={() => {
                                            window.location.replace(process.env.REACT_APP_API_URL + 'linkedin/auth/redirect')
                                        }}
                                            disabled={loading}
                                    >
                                        <img src={require('./../../assets/images/linkedin_logo_initials.png')} alt="Logo linkedin"
                                             className="rounded-4xl responsive_height !w-6 mr-4"/>
                                        {intl.formatMessage({id: 'login.linkedin'})}
                                    </button>
                                    :
                                    null
                            }

                            {
                                first && first == 'new' && !loading ?
                                    <button type="button"
                                            className="px-8 py-2 text-secondary border-2 border-secondary rounded-full w-full"
                                            onClick={handleResendEmail}
                                            disabled={loading}
                                    >
                                        {intl.formatMessage({id: 'resend.validation.code'})}
                                    </button>
                                    :
                                    null
                            }

                            {
                                first && first == 'resend' && !loading ?
                                    <button type="button"
                                            className="px-8 py-2 text-secondary border-2 border-secondary rounded-full w-full"
                                            onClick={handleResendEmail}
                                            disabled={loading}
                                    >
                                        {intl.formatMessage({id: 'resend.validation.code'})}
                                    </button>
                                    :
                                    null
                            }

                        </div>
                    </section>

                    <section></section>

                    <section className="flex justify-center items-center space-x-4 text-xs">
                        <Link to={'/register/new'}
                              className="px-4 py-1 rounded-full bg-secondary cursor-pointer text-white">{intl.formatMessage({id: 'new.register'})}
                        </Link>
                        <Link to={'/login/resend'}
                              className="px-4 py-1 rounded-full bg-secondary cursor-pointer text-white">{intl.formatMessage({id: 'forgot.password'})}
                        </Link>
                    </section>

                    <section></section>
                    <section className="flex justify-center items-center space-x-4 text-xs">
                        <FormControl className={'ml-4'}>
                            <InputLabel id="demo-simple-select-standard-label">
                                {intl.formatMessage({id: 'language'})}
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={locale.short}
                                label={intl.formatMessage({id: 'language'})}
                                onChange={(e) => dispatch(changeLocale(getLocale(e.target.value)))}
                                className={'text-left'}
                                size={'small'}
                            >
                                {language?.filter(function (lang) {
                                    if (lang.code == "en" || lang.code == 'fr') {
                                        return true
                                    }
                                }).map(option => {
                                    return (
                                        <MenuItem key={option.code} value={option.code}>
                                            {capitalizeFirstLetter(option.name)}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </section>
                </form>


            </main>

            <Snackbar
                open={showAlert} //autoHideDuration={6000}
                onClose={() => setShowAlert(false)} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            >
                <MuiAlert onClose={() => setShowAlert(false)} severity="error" sx={{width: '100%'}}>
                    {alertMsg}
                </MuiAlert>
            </Snackbar>
        </div>
    )
}

export default injectIntl(Login)
