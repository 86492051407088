export function getLocale(locale) {
    const defaultLang = process.env.REACT_APP_DEFAULT_LOCALE

    if (locale && (typeof locale === 'string')) {
        locale = locale.toLowerCase()
    } else {
        locale = null
    }

    switch (locale || defaultLang) {
        case 'en':
        case 'en-us':
        case 'en-uk':
        case 'en_us':
        case 'en_uk':
            return {
                short: 'en',
                specific: 'enUS'
            }

        case 'fr':
        case 'fr-fr':
        case 'fr_fr':
            return {
                short: 'fr',
                specific: 'frFR'
            }

        default:
            return {
                short: 'fr',
                specific: 'frFR'
            }
    }
}

export function addDays(days) {
    var result = new Date();
    result.setDate(result.getDate() + days);
    return result;
}

export function formatDigit(myNumber, noDigits) {
    var sign = myNumber ? myNumber < 0 ? -1 : 1 : 0;
    myNumber = myNumber * sign + ''; // poor man's absolute value
    var dec = myNumber.match(/\.\d+$/);
    var int = myNumber.match(/^[^\.]+/);

    var formattedNumber = (sign < 0 ? '-' : '') + ("0" + int).slice(noDigits) + (dec !== null ? dec : '');
    return formattedNumber;
}

export function isObject(obj) {
    return obj != null && obj.constructor.name === "Object"
}
export function wait(ms) {
    return new Promise(res => setTimeout(res, ms));
}
export function replaceString(str, object) {
    for (const key in object) {
        str = str.replace(new RegExp(key, 'g'), object[key]);
    }
    return str
}
export function timeBetweenTimezone(timezoneName1, timezoneName2) {
    const now = new Date();
    const options = { timeZone: timezoneName1 };
    const hour1 = now.toLocaleString("en-US", options).split(", ")[1].split(":")[0];
    options.timeZone = timezoneName2;
    const hour2 = now.toLocaleString("en-US", options).split(", ")[1].split(":")[0];

    if ((hour1 - hour2) > 0) {
        return '+' + (hour1 - hour2);
    }

    return hour1 - hour2;
}
export function isOverLimitEmployed(amount, currency_code) {
    let overlimit = false;

    switch (currency_code) {
        case 'eur':
            overlimit = amount > 10000;
            break;
        case 'usd':
            overlimit = amount > 10000;
            break;
        case 'mur':
            overlimit = amount > 500000;
            break;
        case 'mad':
            overlimit = amount > 100000;
            break;
        case 'mga':
            overlimit = amount > 45000000;
            break;
        case 'xof':
        case 'xaf':
            overlimit = amount > 6000000;
            break;
    }

    return overlimit
}

export function arraysContainSameElements(array1, array2) {
    // Check if arrays have the same length
    if (array1.length !== array2.length) {
        return false;
    }

    // Create copies of the arrays to avoid modifying the originals
    const copy1 = array1.slice().sort();
    const copy2 = array2.slice().sort();

    // Check if each element in the sorted arrays is equal
    for (let i = 0; i < copy1.length; i++) {
        if (copy1[i] !== copy2[i]) {
            return false;
        }
    }

    // If all elements are equal and arrays have the same length, return true
    return true;
}