import {axiosInstance} from '../../axios/axios'

export const createRefRequest = async (token, apiValues, locale) =>
    await axiosInstance.post('/api/ref/create', apiValues, {
        headers: {
            Authorization: 'Bearer ' + token,
            'Accept-language': locale?.short
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const autoRefRequest = async (token, locale, form) =>
    await axiosInstance.post('/api/ref/autocomplete', form, {
        headers: {
            Authorization: 'Bearer ' + token,
            'Accept-language': locale?.short
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const trackRequest = async (token, action) =>
    await axiosInstance.get('/api/ref/user_activity/' + action,  {
        headers: {
            Authorization: 'Bearer ' + token,
            'Accept-language': 'fr'
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })


