export const pagesNav = [
    {
        name: 'recruitment',
        path: '/recruitment',
        icon: 'fa-solid fa-user-plus',
        options: [
            "Are you looking for your future talent?",
            "Consult the talent pool, post a job offer, and track your applications"
        ],
        subs: [
            {
                name: 'consult_vivier_talent',
                path: '/company/search/talents',
                icon: 'fa-solid fa-magnifying-glass',
                options: [
                    'Filtrez les candidats',
                    'Comparez les talents',
                    'Ajoutez à vos favoris',
                ],
                button_name: "Consulter",
                break: false
            },
            {
                name: 'consult_talent_favoris',
                path: '/company/job_offer/search/talents/favorites',
                icon: 'fa-solid fa-user-check',
                options: [
                    'Constituez vos favoris',
                    'Comparez vos favoris',
                    'Proposez des entretiens',
                ],
                button_name: "Consulter",
                break: true
            },
            {
                name: 'consultPublish_job_offer',
                path: '/company/job_offer/list',
                icon: 'fa-solid fa-briefcase',
                options: [
                    'Rédigez la fiche de poste',
                    'Créez une offre d\'emploi',
                    'Publiez l\'offre d\'emploi',
                ],
                button_name: "Consulter",
                break: false
            },
            {
                name: 'view_applications',
                path: '/company/job_offer/candidature',
                icon: 'fa-solid fa-user-tie',
                options: [
                    'Consultez les candidats',
                    'Proposez des entretiens',
                    'Retenez les meilleurs',
                ],
                button_name: "Consulter",
                break: false
            },
            /*{
                name: 'talent_personae',
                path: '/company/job_offer/ideal_candidate',
                icon: 'fa-solid fa-user-graduate',
                options: [
                    'Créez votre candidat idéal',
                    'Editez sa fiche de poste',
                    'Paramétrez vos alertes',
                ],
                button_name: "Consulter",
                break: false
            },*/
        ]
    },
    {
        name: 'portage',
        path: '/portage',
        icon: 'fa-solid fa-user-shield',
        options: [
            "Have you already found your talent?",
            "Request a payroll contract and invite your talent to join Talenteum"
        ],
        subs: [
            {
                name: 'port_talent_employee',
                path: '/company/port_talent_employee',
                icon: 'fa-solid fa-user-tag',
                options: [
                    'Simulez son coût mensuel',
                    'Communiquez ses coordonnées',
                    'Attribuez-lui un contrat de travail',
                ],
                button_name: "Demander",
                break: false
            },
            {
                name: 'port_talent_prestataire',
                path: '/company/port_talent_prestataire',
                icon: 'fa-solid fa-user-tag',
                options: [
                    'Simulez son coût mensuel',
                    'Communiquez ses coordonnées',
                    'Attribuez-lui un contrat de prestation',
                ],
                button_name: "Demander",
                break: false
            }
        ]
    },
    {
        name: 'parainage',
        path: '/parainage',
        icon: 'fa-solid fa-hand-holding-hand',
        options: [
           "Ready to grow the network?",
           "Refer a talent, a company, and manage your community"
        ],
        subs: [
            {
                name: 'refer_talent',
                path: '/company/refer_talent',
                icon: 'fa-solid fa-hands-holding-child',
                options: [
                    'Invitez un talent à devenir membre',
                    'Donnez-lui accès à des offres d\'emploi',
                    'Augmentez vos revenus',
                ],
                button_name: "Parrainer",
                break: false
            },
            {
                name: 'refer_entreprise',
                path: '/company/refer_entreprise',
                icon: 'fa-solid fa-hand-holding-dollar',
                options: [
                    'Invitez une entreprise à devenir membre',
                    'Donnez-lui accès à un vivier de talents',
                    'Augmentez vos revenus',
                ],
                button_name: "Parrainer",
                break: false
            },
            /*{
                name: 'my_referrals',
                path: '/company/my_referrals',
                icon: 'fa-solid fa-people-arrows',
                options: [
                    'Consultez mes filleuls',
                    'Suivez les sous-filleuls',
                    'Gérez les primes de parrainage',
                ],
                button_name: "Consulter",
                break: false
            }*/
        ]
    }
]

export const pagesAccNav = [
    {
        name: 'account',
        path: '/account',
        icon: 'fa-solid fa-circle-user',
        options: [
            'Notez vos préférences',
            'Inscrivez vos données perso',
            'Changez le mot de passe',
        ],
        subs: [
            // {
            //     name: 'preferences',
            //     path: '/company/preferences',
            //     icon: 'fa-solid fa-asterisk',
            //     options: [
            //         'Changez votre devise, fuseau horaire et langue',
            //     ],
            //     button_name: "Consulter",
            //     break: false
            // },
            // {
            //     name: 'avatar',
            //     path: '/company/avatar',
            //     icon: 'fa-solid fa-id-badge',
            //     options: [
            //         'Changez votre avatar',
            //     ],
            //     button_name: "Consulter",
            //     break: true
            // },
            // {
            //     name: 'coordonnes',
            //     path: '/company/contact',
            //     icon: 'fa-solid fa-address-book',
            //     options: [
            //         'Changez votre nom, prénom et fonction',
            //     ],
            //     button_name: "Consulter",
            //     break: false
            // },
            // {
            //     name: 'personal_info',
            //     path: '/company/confidentiel',
            //     icon: 'fa-solid fa-id-card-clip',
            //     options: [
            //         'Changez votre date de naissance et civilité',
            //     ],
            //     button_name: "Consulter",
            //     break: true
            // },
            // {
            //     name: 'mdp',
            //     path: '/company/mdp',
            //     icon: 'fa-solid fa-lock',
            //     options: [
            //         'Changez votre mot de passe'
            //     ],
            //     button_name: "Consulter",
            //     break: false
            // },
            {
                name: 'my.profile',
                path: '/company/profil',
                icon: 'fa-solid fa-file',
                options: [
                    'Mes données personnelles',
                    'Mes préférences de recherche d’emploi',
                    'Mes documents personnels',
                    'Mot de passe'
                ],
                button_name: "Consulter",
                break: true
            },
            {
                name: 'disconnect',
                path: '/disconnect',
                icon: 'fa-solid fa-right-from-bracket',
                options: [
                    'Déconnectez-vous',
                ],
                button_name: "Déconnexion",
                break: false
            }
        ]
    },
]

export const pagesCompanyNav = [
    {
        name: 'mon_entreprise',
        path: '/company/dashboard',
        icon: 'fa-solid fa-building',
        options: [
            'Manage your invoices, your teams, and the settings of your business account'
        ],
        subs: [
            {
                name: 'companyProfil',
                path: '/company/about',
                icon: 'fa-solid fa-phone',
                options: [
                    'Inserez vos coordonnées',
                ],
                button_name: "Consulter",
                break: true
            },
            // {
            //     name: 'society_contact',
            //     path: '/company/society',
            //     icon: 'fa-solid fa-phone',
            //     options: [
            //         'Inserez vos coordonnées',
            //     ],
            //     button_name: "Consulter",
            //     break: false
            // },
            // {
            //     name: 'juridique',
            //     path: '/company/document',
            //     icon: 'fa-solid fa-file',
            //     options: [
            //         'Gérez vos documents',
            //     ],
            //     button_name: "Consulter",
            //     break: true
            // },
            // {
            //     name: 'collegues',
            //     path: '/company/collab',
            //     icon: 'fa-solid fa-people-group',
            //     options: [
            //         'Invitez vos collègues',
            //     ],
            //     button_name: "Consulter",
            //     break: false
            // },
            // {
            //     name: 'access',
            //     path: '/company/group',
            //     icon: 'fa-solid fa-unlock',
            //     options: [
            //         'Gérez les accès',
            //     ],
            //     button_name: "Consulter",
            //     break: true
            // },
            // {
            //     name: 'company_icon',
            //     path: '/company/icon',
            //     icon: 'fa-solid fa-paint-roller',
            //     options: [
            //         'Choississez votre icône',
            //     ],
            //     button_name: "Consulter",
            //     break: false
            // },
            // {
            //     name: 'marque_employeur',
            //     path: '/company/values',
            //     icon: 'fa-solid fa-copyright',
            //     options: [
            //         'Renseignez votre marque employeur',
            //     ],
            //     button_name: "Consulter",
            //     break: true
            // },
            {
                name: 'Manage my teams',
                path: '/company/my_team',
                icon: 'fa-solid fa-users-between-lines',
                options: [
                    'Gérez les membres de l\'équipe',
                ],
                button_name: "Consulter",
                break: false
            },
            {
                name: 'Manage my invoices',
                path: '/company/invoices',
                icon: 'fa-solid fa-file-invoice',
                options: [
                    'Consultez vos factures'
                ],
                button_name: "Consulter",
                break: false
            },
        ]
    },
]

export const TuilCompanyProfile = [
    {
        name: "company_information",
        icon:  <svg xmlns="http://www.w3.org/2000/svg" height="80px" viewBox="0 -960 960 960" width="80px" fill="#16a34a"><path d="M480-400q33 0 56.5-23.5T560-480q0-33-23.5-56.5T480-560q-33 0-56.5 23.5T400-480q0 33 23.5 56.5T480-400ZM320-240h320v-23q0-24-13-44t-36-30q-26-11-53.5-17t-57.5-6q-30 0-57.5 6T369-337q-23 10-36 30t-13 44v23ZM740-80H220q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h341l239 239v501q0 24-18 42t-42 18Zm0-60v-474L534-820H220v680h520Zm-520 0v-680 680Z"/></svg>,
        description: "company_info_description",
        link: "/company/info"
    },
    {
        name: "collegues",
        icon: <svg xmlns="http://www.w3.org/2000/svg" height="80px" viewBox="0 -960 960 960" width="80px" fill="#16a34a"><path d="M0-240v-53q0-38.57 41.5-62.78Q83-380 150.38-380q12.16 0 23.39.5t22.23 2.15q-8 17.35-12 35.17-4 17.81-4 37.18v65H0Zm240 0v-65q0-32 17.5-58.5T307-410q32-20 76.5-30t96.5-10q53 0 97.5 10t76.5 30q32 20 49 46.5t17 58.5v65H240Zm540 0v-65q0-19.86-3.5-37.43T765-377.27q11-1.73 22.17-2.23 11.17-.5 22.83-.5 67.5 0 108.75 23.77T960-293v53H780Zm-480-60h360v-6q0-37-50.5-60.5T480-390q-79 0-129.5 23.5T300-305v5ZM149.57-410q-28.57 0-49.07-20.56Q80-451.13 80-480q0-29 20.56-49.5Q121.13-550 150-550q29 0 49.5 20.5t20.5 49.93q0 28.57-20.5 49.07T149.57-410Zm660 0q-28.57 0-49.07-20.56Q740-451.13 740-480q0-29 20.56-49.5Q781.13-550 810-550q29 0 49.5 20.5t20.5 49.93q0 28.57-20.5 49.07T809.57-410ZM480-480q-50 0-85-35t-35-85q0-51 35-85.5t85-34.5q51 0 85.5 34.5T600-600q0 50-34.5 85T480-480Zm.35-60Q506-540 523-557.35t17-43Q540-626 522.85-643t-42.5-17q-25.35 0-42.85 17.15t-17.5 42.5q0 25.35 17.35 42.85t43 17.5ZM480-300Zm0-300Z"/></svg>,
        description: "collegue_description",
        link: '/company/collab'
    },
    {
        name: "marque_employeur",
        icon:<svg xmlns="http://www.w3.org/2000/svg" height="80px" viewBox="0 -960 960 960" width="80px" fill="#16a34a"><path d="m344-60-76-128-144-32 14-148-98-112 98-112-14-148 144-32 76-128 136 58 136-58 76 128 144 32-14 148 98 112-98 112 14 148-144 32-76 128-136-58-136 58Zm34-102 102-44 104 44 56-96 110-26-10-112 74-84-74-86 10-112-110-24-58-96-102 44-104-44-56 96-110 24 10 112-74 86 74 84-10 114 110 24 58 96Zm102-318Zm-42 142 226-226-56-58-170 170-86-84-56 56 142 142Z"/></svg>,
        description: "marque_employer_description",
        link: "/company/values"
    }
]

export const profileMenuTuilCompany = [
    {
        name: "my.personal.data",
        icon:  <svg xmlns="http://www.w3.org/2000/svg" height="80px" viewBox="0 -960 960 960" width="80px" fill="#16a34a"><path d="M480-400q33 0 56.5-23.5T560-480q0-33-23.5-56.5T480-560q-33 0-56.5 23.5T400-480q0 33 23.5 56.5T480-400ZM320-240h320v-23q0-24-13-44t-36-30q-26-11-53.5-17t-57.5-6q-30 0-57.5 6T369-337q-23 10-36 30t-13 44v23ZM740-80H220q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h341l239 239v501q0 24-18 42t-42 18Zm0-60v-474L534-820H220v680h520Zm-520 0v-680 680Z"/></svg>,
        description: "my.personal.data.description",
        link: "/company/profil-personal-data"
    },
    {
        name: "job.search.preference",
        icon: <svg xmlns="http://www.w3.org/2000/svg" height="80px" viewBox="0 -960 960 960" width="80px" fill="#16a34a"><path d="M796-121 533-384q-30 26-70 40.5T378-329q-108 0-183-75t-75-181q0-106 75-181t182-75q106 0 180.5 75T632-585q0 43-14 83t-42 75l264 262-44 44ZM377-389q81 0 138-57.5T572-585q0-81-57-138.5T377-781q-82 0-139.5 57.5T180-585q0 81 57.5 138.5T377-389Zm-74-89 29-87-73-56h87l30-87 30 87h86l-73 56 29 87-72-55-73 55Z"/></svg>,
        description: "job.search.preference.description",
        link: '/company/preferences'
    },
    {
        name: "password",
        icon: <svg xmlns="http://www.w3.org/2000/svg" height="80px" viewBox="0 -960 960 960" width="80px" fill="#16a34a"><path d="M80-200v-61h800v61H80Zm38-254-40-22 40-68H40v-45h78l-40-68 40-22 38 67 38-67 40 22-40 68h78v45h-78l40 68-40 22-38-67-38 67Zm324 0-40-24 40-68h-78v-45h78l-40-68 40-22 38 67 38-67 40 22-40 68h78v45h-78l40 68-40 24-38-67-38 67Zm324 0-40-24 40-68h-78v-45h78l-40-68 40-22 38 67 38-67 40 22-40 68h78v45h-78l40 68-40 24-38-67-38 67Z"/></svg>,
        description: "password.description",
        link: "/company/mdp"
    }
]

export const pageLayoutMaxWidth = '1000px'