import {axiosInstance} from '../../axios/axios'

export const getCurrentUserRequest = async (token) =>
    await axiosInstance.post('/api/user/current', {}, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const signInUserWithEmailPasswordRequest = async (email, password) =>
    await axiosInstance.post('/api/login', {
        email: email,
        password: password
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const signOutRequest = async (token) => {
    localStorage.clear()
    return await axiosInstance.post('/api/logout', {}, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
    .then(function (response) {
        window.location.reload();
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
};


export const sendOtpRequest = async (token, form) =>
    await axiosInstance.post('/api/user/send_otp', form, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const validateOtpRequest = async (token, form) =>
    await axiosInstance.post('/api/user/validate_otp', form, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const resetPwdRequest = async (token, form) =>
    await axiosInstance.post('/api/user/reset_mdp', form, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const registerUserRequest = async (form) =>
    await axiosInstance.post('/api/register', form, {
        headers: {
            //Authorization: 'Bearer ' + token
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const registerUserLinkedinRequest = async (token, form) =>
    await axiosInstance.post('/api/register/linkedin', form, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const registerResendEmailRequest = async (form) =>
    await axiosInstance.post('/api/resend_email', form, {
        headers: {
            //Authorization: 'Bearer ' + token
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const getLocation = async (token) =>
    await axiosInstance.get('/api/location', {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const autoLoginRequest = async (uid) =>
    await axiosInstance.post('/api/autologin', {
        uid: uid
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const refuseInvitation = async (uid) =>
    await axiosInstance.post('/api/invitation/refused', {
        uid: uid
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })