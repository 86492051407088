import {
    GET_PORTAGE,
    GET_PORTAGE_SUCCESS,
    STORE_PORTAGE,
    STORE_PORTAGE_SUCCESS,
    CHECK_COMPANY_PORTAGE,
    CHECK_COMPANY_PORTAGE_SUCCESS
} from '../action-types'

const INIT_STATE = {
    portages: [],
    checkPortage: null
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PORTAGE_SUCCESS: {
            return {...state, portages: action?.data}
        }
        case STORE_PORTAGE_SUCCESS: {
            return {...state, portages: action?.data}
        }
        case CHECK_COMPANY_PORTAGE_SUCCESS: {
            return {...state, checkPortage: action?.data}
        }
        default:
            return state
    }
}
