import {axiosInstanceGraphQL} from "../../axios/axios";
import {graphqlJobApplicationFields} from "../../utils/constant";

export const findTalentApplicationRequest = async (token, talentID) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: `{
            jobApplications(
                talent_id: "${talentID}"
            ){
                ${graphqlJobApplicationFields}
            }
        }`
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
            "Content-Type": "application/json"
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const findApplicationByID = async (token, id) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: `{
            jobApplications(id: "${id}"){
                ${graphqlJobApplicationFields}
            }
        }`
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
            "Content-Type": "application/json"
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const updateJobApplicationRequest = async (token, query) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: query
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
            "Content-Type": "application/json"
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const updateJobApplicationFlowRequest = async (token, formData) =>
    await axiosInstanceGraphQL.post('/api/job_offer/application_flow', formData, {
        headers: {
            Authorization: 'Bearer ' + token,
            "Content-Type": "application/json"
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const updateJobApplicationFiche = async (token,applicationId, formData) =>
    await axiosInstanceGraphQL.post('/api/job_offer/update_fiche/'+applicationId, formData, {
        headers: {
            Authorization: 'Bearer ' + token,
            "Content-Type": "application/json"
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const updateJobApplicationNote = async (token, applicationId, formData) =>
    await axiosInstanceGraphQL.post('/api/job_offer/update_note/'+applicationId, formData, {
        headers: {
            Authorization: 'Bearer ' + token,
            "Content-Type": "application/json"
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const updateJobApplicationEvaluation = async (token, applicationId, formData) =>
    await axiosInstanceGraphQL.post('/api/job_offer/update_evaluation/'+applicationId, formData, {
        headers: {
            Authorization: 'Bearer ' + token,
            "Content-Type": "application/json"
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })
