import {FIND_TALENT_APPLICATION_SUCCESS} from "../action-types";

const INIT_STATE = {
    applications: [],
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FIND_TALENT_APPLICATION_SUCCESS: {
            return {...state, applications: action?.data?.data?.jobApplications}
        }

        default:
            return state
    }
}