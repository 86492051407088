import {axiosInstanceES, axiosInstanceGraphQL} from '../../axios/axios'
import {graphqlModulesFields} from "../../utils/constant";

export const getCountriesRequest = async (token, query, locale) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: `{
            ref_country (first: 10000) {
              data {
                name
                code
                region
                sub_region
                phone_prefix
                breedj_currency_code
                present
              }
            }
        }`
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
            'Accept-language': locale?.short
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const getEducationStatusRequest = async (token, query, locale) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: `{
            ref_education_status (first: 10000) {
              data {
                id
                name
              }
            }
        }`
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
            'Accept-language': locale?.short
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const getEducationLevelRequest = async (token, query, locale) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: `{
            ref_education_level (first: 10000) {
              data {
                id
                name
              }
            }
        }`
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
            'Accept-language': locale?.short
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const getEducationRequest = async (token, query, locale) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: `{
            ref_education (first: 10000, name_accent: "${query}", is_active: true) {
              data {
                id
                name
              }
            }
        }`
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
            'Accept-language': locale?.short
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const getCertificationRequest = async (token, query, locale) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: `{
            ref_certification (first: 10000, name_accent: "${query}", is_active: true) {
              data {
                id
                name
              }
            }
        }`
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
            'Accept-language': locale?.short
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const getLanguageRequest = async (token, query, locale) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: `{
            ref_language (first: 10000) {
              data {
                name
                code
                position
              }
            }
        }`
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
            'Accept-language': locale?.short
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const getSkillRequest = async (token, query, locale) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: `{
            ref_skill (first: 10000, name_accent: "${query}", is_active: true) {
              data {
                id
                name
              }
            }
        }`
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
            'Accept-language': locale?.short
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const getHobbyRequest = async (token, query, locale) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: `{
            ref_hobby (first: 10000, name_accent: "${query}") {
              data {
                id
                name
              }
            }
        }`
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
            'Accept-language': locale?.short
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const getSearchUrgencyRequest = async (token, query, locale) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: `{
            ref_search_urgency (first: 10000) {
              data {
                id
                name
              }
            }
        }`
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
            'Accept-language': locale?.short
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const getIndustryRequest = async (token, query, locale) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: `{
            ref_industry (first: 10000) {
              data {
                id
                name
              }
            }
        }`
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
            'Accept-language': locale?.short
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const getSectorRequest = async (token, query, locale) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: `{
            ref_sector (first: 10000) {
              data {
                id
                name
              }
            }
        }`
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
            'Accept-language': locale?.short
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })



export const getSubIndustryRequest = async (token, query, locale) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: `{
            ref_sub_industry (first: 10000) {
              data {
                id
                name
              }
            }
        }`
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
            'Accept-language': locale?.short
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })



export const getSubSubIndustryRequest = async (token, query, locale) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: `{
            ref_sub_sub_industry (first: 10000) {
              data {
                id
                name
              }
            }
        }`
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
            'Accept-language': locale?.short
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const getExpRangeRequest = async (token, query, locale) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: `{
            ref_experience_range (first: 10000) {
              data {
                id
                name
                min
                max
              }
            }
        }`
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
            'Accept-language': locale?.short
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const getRefJobTitleRequest = async (token, query, locale) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: `{
            ref_job_title (first: 20, name_accent: "${query}", is_active: true) {
              data {
                id
                name
              }
            }
        }`
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
            'Accept-language': locale?.short
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const getRefJobTitleRequestES = async (token, query, locale) =>
    await axiosInstanceES.post('/job_title_' + locale?.short + '_index/_search', {
        "version": true,
        "size": 10,
        "sort": [
            {
                "_score": {
                    "order": "desc"
                }
            }
        ],
        "_source": {
            "excludes": []
        },
        "query": {
            "bool": {
                "must": [
                    {
                        query_string: {
                            fields: ['name'],
                            query: `*${query}*`
                        }
                    }
                ],
                "filter": []
            }
        },
    }, {
        headers: {
            'Accept-language': locale?.short
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const getRefRequestES = async (index, query, locale) =>
    await axiosInstanceES.post(index + locale?.short + '_index/_search', {
        "version": true,
        "size": 10,
        "sort": [
            {
                "_score": {
                    "order": "desc"
                }
            }
        ],
        "_source": {
            "excludes": []
        },
        "query": {
            "bool": {
                "must": [
                    {
                        query_string: {
                            fields: ['name'],
                            query: `*${query}*`,
                        }
                    }
                ],
                "filter": []
            }
        },
    }, {
        headers: {
            'Accept-language': locale?.short
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const getRefUniversityRequest = async (token, query, locale) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: `{
            ref_university (first: 20, name_accent: "${query}", is_active: true) {
              data {
                id
                name
              }
            }
        }`
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
            'Accept-language': locale?.short
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const getAvailabilityRequest = async (token, query, locale) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: `{
            ref_availability (first: 20) {
              data {
                id
                name
              }
            }
        }`
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
            'Accept-language': locale?.short
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const getContractTimeRequest = async (token, query, locale) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: `{
            ref_contract_time (first: 20) {
              data {
                id
                name
              }
            }
        }`
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
            'Accept-language': locale?.short
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const getContractLevelRequest = async (token, query, locale) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: `{
            ref_contract_level (first: 20) {
              data {
                id
                name
              }
            }
        }`
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
            'Accept-language': locale?.short
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const getContractModeRequest = async (token, query, locale) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: `{
            ref_contract_mode (first: 20) {
              data {
                id
                name
              }
            }
        }`
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
            'Accept-language': locale?.short
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const getContractTypeRequest = async (token, query, locale) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: `{
            ref_contract_type (first: 20) {
              data {
                id
                name
              }
            }
        }`
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
            'Accept-language': locale?.short
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const getWorkLocationRequest = async (token, query, locale) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: `{
            ref_work_location (first: 20) {
              data {
                id
                name
                talent_name
                company_name
              }
            }
        }`
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
            'Accept-language': locale?.short
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const getAvatarRequest = async (token, query, locale) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: `{
            ref_avatar (first: 100) {
              data {
                id
                gender_id
                filename
                type
                industry_id
              }
            }
        }`
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
            'Accept-language': locale?.short
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const getGenderRequest = async (token, query, locale) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: `{
            ref_gender (first: 100) {
              data {
                id
                name
              }
            }
        }`
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
            'Accept-language': locale?.short
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const getCityRequest = async (token, query, locale) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: `{
            ref_city (first: 100, name: "${query}") {
              data {
                id
                name
              }
            }
        }`
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
            'Accept-language': locale?.short
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const getCurrencyRequest = async (token, query, locale) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: `{
            ref_currency (first: 250) {
              data {
                code
                name
                symbol
                active
              }
            }
        }`
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
            'Accept-language': locale?.short
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const getValueRequest = async (token, query, locale) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: `{
            ref_value (first: 35) {
              data {
                id
                name
                type
              }
            }
        }`
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
            'Accept-language': locale?.short
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const getTimezoneRequest = async (token, query, locale) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: `{
            ref_timezone (first: 1000) {
              data {
                id
                name
                value
                country
                region
                countries {
                    country_code
                }
              }
            }
        }`
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
            'Accept-language': locale?.short
        }
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })

export const getModulesRequest = async (token, query, locale) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: `{
            ref_modules{
                ${graphqlModulesFields}
            }
        }`
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
            'Accept-language': locale?.short
        }
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })