import enUS from "./locales/en_US.json"
import frFR from "./locales/fr_FR.json"

const translations = {
    messages: {
        enUS,
        frFR,
    }
}

export default translations
