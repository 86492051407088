import {axiosInstance, axiosInstanceES, axiosInstanceGraphQL} from '../../axios/axios'

export const getTalentRequest = async (token, query) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: query
    }, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const getTalentsRequest = async (token, query) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: query
    }, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })


export const getTalentsESRequest = async (token, query, locale) =>
    await axiosInstanceES.post('/talent_search_index/_search', query, {
        headers: {
            'Accept-language': locale?.short
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const storeTalentRatingRequest = async (token,values) =>
    await axiosInstance.post(`/api/ratings?token=${token}`,values)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const updateTalentRequest = async (token, query) =>
    await axiosInstanceGraphQL.post('/graphql', {
        query: query
    }, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const updateTalentMultipleRequest = async (token, table, value) =>
    await axiosInstanceGraphQL.post('/api/talent/update_multiple', {
        table: table,
        value: value
    }, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const updateTalentMultipleLevelRequest = async (token, table, key, value) =>
    await axiosInstanceGraphQL.post('/api/talent/update_multiple_level', {
        table: table,
        key: key,
        value: value
    }, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const deleteTalentMultipleRequest = async (token, table, value) =>
    await axiosInstanceGraphQL.post('/api/talent/delete_multiple', {
        table: table,
        value: value
    }, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const presentationTalentRequest = async (token) =>
    await axiosInstanceGraphQL.get('/api/talent/presentation', {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const updateSemiProfileRequest = async (token, query) =>
    await axiosInstanceGraphQL.post('/api/talent/semi_profile', query, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const updateProfileStatusRequest = async (token) =>
    await axiosInstanceGraphQL.post('/api/talent/update_profile_status', {}, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const getSalaryDetailsRequest = async (token, country, salary, from_currency, to_currency) =>
    await axiosInstanceGraphQL.post('/api/talent/salary_details', {
        country: country,
        salary: salary,
        from_currency: from_currency,
        to_currency: to_currency
    }, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const getSalaryTaxDetailsRequest = async (token, country, salary, from_currency, to_currency) =>
    await axiosInstanceGraphQL.post('/api/talent/salary_tax_details', {
        country: country,
        salary: salary,
        from_currency: from_currency,
        to_currency: to_currency
    }, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const getFullSalaryTax = async (token, country, salary, from_currency, to_currency) =>
    await axiosInstanceGraphQL.post('/api/talent/full_salary_tax_details', {
        country: country,
        salary: salary,
        from_currency: from_currency,
        to_currency: to_currency
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error.response
    })


export const checkAutoProfileFinished =  async (token) =>

    await axiosInstance.post('/api/talent/check_auto_profile_finish', {}, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const upsertTimesheetsRequest =  async (token, timesheet) =>

    await axiosInstance.post('/api/talent/timesheet', timesheet, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const upsertExpenseReportsRequest =  async (token, expense_report) =>

    await axiosInstance.post('/api/talent/expense_report', expense_report, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const deleteExpenseReportsRequest =  async (token, id) =>

    await axiosInstance.delete('/api/talent/expense_report/delete/' + id, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const upsertContractorInvoicesRequest =  async (token, timesheet) =>

    await axiosInstance.post('/api/talent/contractor_invoice', timesheet, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const upsertHolidaysRequest =  async (token, expense_report) =>

    await axiosInstance.post('/api/talent/holidays', expense_report, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const deleteHolidaysRequest =  async (token, id) =>

    await axiosInstance.delete('/api/talent/holidays/delete/' + id, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const upsertBonusRequest =  async (token, expense_report) =>

    await axiosInstance.post('/api/talent/bonus', expense_report, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const unsubscribeNotification = async (token) =>
    await axiosInstance.get(`/api/talents/unsubscribe/${token}`)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const upsertSurveyRequest =  async (token, expense_report) =>

    await axiosInstance.post('/api/talent/survey', expense_report, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const getContractorInvoicesExplainRequest =  async (token, form) =>

    await axiosInstance.post('/api/talent/contractor_invoice/explain', form, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })