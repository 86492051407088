import React, {useEffect, useState} from 'react'
import {injectIntl} from 'react-intl'
import {useSelector} from "react-redux";
import {Link, useLocation} from 'react-router-dom'
import {routeList, renderDoneCheck} from "../../utils/routes";

const LeftAside = ({intl, list}) => {

    const {user} = useSelector(({auth}) => auth)
    const {talent} = useSelector(({talent}) => talent)
    const {pathname} = useLocation();

    const [currentRouteList, setCurrentRouteList] = useState([])

    useEffect(() => {
        if (talent) {
            setCurrent();
        }
    }, [talent])

    const setCurrent = () => {
        setCurrentRouteList(routeList)
    }

    if (talent?.profile_status === 'active') {
        return (
            <div className="flex flex-col my-6 w-1/5 min-w-[300px] hide_on_mobile">
                <aside className="p-8 shadow-xxl rounded-4xl">
                    <ul className="flex flex-col py-2 space-y-5 text-left">
                        {currentRouteList?.map((option, index) => {
                            return (
                                <li key={index}>
                                    <Link to={option.path} className={option.path === pathname ? 'text-secondary' : ''}>
                                        {/*<i className={option.icon + " text-right w-4 mr-2"}></i>*/}
                                        {renderDoneCheck(option.path, talent, user)}
                                        <span className=" ">{intl.formatMessage({id: option.label})}</span>

                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </aside>
            </div>
        )
    }
}

export default injectIntl(LeftAside)

