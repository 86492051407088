import {axiosInstance} from '../../axios/axios'

export const getPortagesRequest = async (token, id) =>
    await axiosInstance.get('/api/portages?contract_type_id=' + id, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const storePortageRequest = async (token, values) =>
    await axiosInstance.post('/api/portages/store', values, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const checkPortageRequest = async (token) =>
    await axiosInstance.get('/api/portages/check_company', {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

