import {
    DELETE_JOB_OFFER_SUCCESS,
    GET_JOB_OFFER_SUCCESS,
    GET_JOB_OFFERS_SUCCESS,
    SET_JOB_OFFER_STATE, UPDATE_APPLICATION_FLOW_SUCCESS, UPDATE_APPLICATION_TIME_SUCCESS, UPSERT_JOB_OFFER_SUCCESS,
    JOB_OFFER_FICHE_SUCCESS, GET_JOB_OFFERS_COUNT_SUCCESS, GET_JOB_OFFERS_COUNT,
    JOB_OFFER_PROPOSAL_SUCCESS, JOB_OFFER_CONTRACT_SUCCESS, JOB_OFFER_CONTRACT_SIGN_SUCCESS,
    JUST_JOB_OFFER_PROPOSAL, JOB_OFFER_CONTRACT_PROPOSAL_SUCCESS
} from '../action-types'

const INIT_STATE = {
    job_offer: null,
    job_offers: [],
    proposals: [],
    contract: null,
    job_offer_fiche: null,
    paginator: {
        currentPage: 1,
        lastPage: 1,
        total: 0
    },
    paginatorCount: {
        currentPage: 1,
        lastPage: 1,
        total: 0
    },
    paginatorCountLoading: false,
    presentation: "",
    contract_application: null
}

export default (state = INIT_STATE, action) => {

    switch (action.type) {
        case GET_JOB_OFFERS_SUCCESS: {
            return {...state, job_offers: action?.data?.job_offers, paginator: action?.data?.job_offers?.paginatorInfo, paginatorCount: action?.data?.job_offers?.paginatorInfo}
        }

        case GET_JOB_OFFERS_COUNT: {
            return {...state, paginatorCountLoading: true}
        }

        case GET_JOB_OFFERS_COUNT_SUCCESS: {
            return {...state, paginatorCount: action?.data?.job_offers?.paginatorInfo, paginatorCountLoading: false}
        }

        case GET_JOB_OFFER_SUCCESS: {
            return {...state, job_offer: action?.data?.jobOfferById}
        }

        case JOB_OFFER_FICHE_SUCCESS: {
            return {...state, job_offer_fiche: action?.data}
        }

        case DELETE_JOB_OFFER_SUCCESS: {
            let deletedId = action?.data?.deleteJobOffer?.id
            if(deletedId) {
                state.job_offers.data = state.job_offers.data.filter((job_offer) => job_offer.id !== deletedId)
            }

            return {...state }
        }

        case UPSERT_JOB_OFFER_SUCCESS: {
            let updatedJobOffer = action?.data?.data?.upsertJobOffer || state.job_offer

            return {...state, job_offer: updatedJobOffer}
        }

        case JOB_OFFER_PROPOSAL_SUCCESS: {
            return {...state, proposals: action?.data}
        }

        case JOB_OFFER_CONTRACT_SUCCESS: {
            return {...state, contract: action?.data}
        }

        case JOB_OFFER_CONTRACT_SIGN_SUCCESS: {
            return {...state, contract: null}
        }

        case JUST_JOB_OFFER_PROPOSAL: {
            let talentProposals = state.proposals;

            for (let i = 0; i < state.proposals.length; i++) {
                if (state.proposals[i].id == action?.data?.id) {
                    talentProposals[i] = action?.data;
                    break;
                }
            }
            talentProposals = JSON.parse(JSON.stringify(talentProposals))

            return {...state, proposals: talentProposals}
        }

        case SET_JOB_OFFER_STATE: {
            return {
                ...state,
                ...action?.payload
            }
        }

        case UPDATE_APPLICATION_TIME_SUCCESS:{
            let indexJob = ''
            let indexApplication = ''

            for (let i = 0; i < state.job_offers?.data?.length; i++) {
                if (state.job_offers.data[i].applications) {
                    for (let j = 0; j < state.job_offers.data[i].applications.length; j++) {
                        if (state.job_offers.data[i].applications[j].id == action.data.id) {
                            indexJob = i
                            indexApplication = j
                            break
                        }
                    }
                }
            }

            let copyJobOffers = state.job_offers;
            if (indexJob !== '' && indexApplication !== '') {
                copyJobOffers.data[indexJob].applications[indexApplication].step = action.data.step;
                copyJobOffers.data[indexJob].applications[indexApplication].status = action.data.status;
                if (action.data.times) {
                    copyJobOffers.data[indexJob].applications[indexApplication].times = action.data.times;
                }
            }

            return {...state, job_offers: copyJobOffers}
        }

        case UPDATE_APPLICATION_FLOW_SUCCESS: {
            let indexJob = ''
            let indexApplication = ''
            for (let i = 0; i < state.job_offers.data.length; i++) {
                if (state.job_offers.data[i].applications) {
                    for (let j = 0; j < state.job_offers.data[i].applications.length; j++) {
                        if (state.job_offers.data[i].applications[j].id == action.data.id) {
                            indexJob = i
                            indexApplication = j
                            break
                        }
                    }
                }
            }

            let copyJobOffers = state.job_offers;
            if (indexJob !== '' && indexApplication !== '') {
                copyJobOffers.data[indexJob].applications[indexApplication].step = action.data.step;
                copyJobOffers.data[indexJob].applications[indexApplication].status = action.data.status;
                if (action.data.times) {
                    copyJobOffers.data[indexJob].applications[indexApplication].times = action.data.times;
                }
                if (action.data.proposal) {
                    copyJobOffers.data[indexJob].applications[indexApplication].proposal = action.data.proposal;
                }
            }
            copyJobOffers = JSON.parse(JSON.stringify(copyJobOffers))

            let copyProposals = state.proposals;
            for (let i = 0; i < state.proposals.length; i++) {
                if(state.proposals[i].id == action.data.proposal.id) {
                    copyProposals[i] = action.data.proposal;
                    break;
                }
            }
            copyProposals = JSON.parse(JSON.stringify(copyProposals))

            return {...state, job_offers: copyJobOffers, proposals: copyProposals}
        }

        case JOB_OFFER_CONTRACT_PROPOSAL_SUCCESS: {
            return {...state, contract_application: action.data}
        }

        default:
            return state
    }
}
