import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import {injectIntl} from "react-intl"
import {getAvatar} from '../../../redux/actions/List'
import {setTalentState, updateTalent} from '../../../redux/actions/Talent'
import LeftAside from "../../../components/Talent/leftAside";
import {graphqlTalentFields} from "../../../utils/constant"
import {pageLayoutMaxWidth} from "../../../utils/nav_company";
import PageTitle from "../../../components/common/PageTitle";
import ValidateButton from "../../../components/common/ValidateButton";
import {track} from "../../../redux/actions/Ref";


const PersonalData = ({intl}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const {token, user} = useSelector(({auth}) => auth)
    const {locale} = useSelector(({common}) => common)
    const {avatar} = useSelector(({list}) => list)
    const {talent} = useSelector(({talent}) => talent)

    const [formTalent, setFormTalent] = useState({})

    useEffect(() => {
        dispatch(track(token, 103))
    },[])

    useEffect(() => {
        dispatch(getAvatar(token, {}, locale))
    }, [token])

    useEffect(() => {
        if (talent) {
            setFormTalent(talent)
        }
    }, [talent])

    const onUpdateTalent = (avatar_id, gender_id) => {
        dispatch(setTalentState({
            key: 'avatar_id',
            value: avatar_id
        }))
        dispatch(updateTalent(token, `
            mutation {
                    upsertTalent(
                        id: "${user.relation}", 
                        avatar_id: "${avatar_id}", 
                        gender_id: "${gender_id}"
                    ) {
                        ${graphqlTalentFields}
                }
            }
        `,false,false,intl,"avatar"))
    }

    const goToTalentNeed = () => {
        navigate('/talent/need')
    }

    const renderSelectedAvatar = () => {
        let currentAvatar = undefined;

        if (talent) {
            avatar.forEach((option, index) => {
                if (option.id === talent.avatar_id) {
                    currentAvatar = option;
                }
            })

            if (currentAvatar) {
                return (
                    <div className="flex justify-center">
                        <img src={require('./../../../assets/avatars/' + currentAvatar.filename)}
                             alt="Avatar séléctionée" className="rounded-full w-36"/>
                    </div>
                )
            } else {

                return (
                    <div className="flex justify-center">
                        <h2 className="font-semibold text-secondary text-center">{intl.formatMessage({id: 'choose.an.avatar'})}</h2>
                    </div>
                )
            }
        }
    }

    const renderButton = () => {
        if (talent?.profile_status === 'active') {
            return (
                <div className="flex items-center justify-center space-x-6">
                    <div className="flex flex-col">
                        <Link to={'/talent/mdp'}>
                            <button type="button" className="px-6 py-2 rounded-full bg-secondary text-white">
                                {intl.formatMessage({id: 'next.step'})}
                            </button>
                        </Link>
                    </div>
                </div>
            )
        }

        if (talent?.profile_status === 'new') {
            return (
                <div className="flex items-center justify-center space-x-6">
                    <div className="flex flex-col">
                        <Link to={'/talent/need'}>
                            <button type="button" className="px-6 py-2 rounded-full bg-secondary text-white">
                                {intl.formatMessage({id: 'validate'})}
                            </button>
                        </Link>
                    </div>
                </div>
            )
        }
    }

    const renderNormalPage = () => {
        return (
            <div className="flex min-h-screen_custom justify-center grow">
                <div style={{maxWidth: '1300px'}} className="flex flex-col px-6">
                    <div className={'flex space-x-6'}>
                        <div className={'flex flex-col flex-grow'}>

                            <PageTitle title={'my.avatar'} />
                        
                            <div className="flex space-x-8">
                                <div className="flex flex-col flex-1 p-10 space-y-4 shadow-xxl rounded-4xl">
                                    {renderSelectedAvatar()}
                                    <div className="grid grid-cols-3 md:grid-cols-6 lg:grid-cols-12 gap-4 p-2 overflow-y-auto">
                                        {avatar?.filter(avatar => avatar.type === 'talent').map(option => {
                                            return (
                                                <img key={option.id}
                                                     src={require('./../../../assets/avatars/' + option.filename)}
                                                     alt="Avatar"
                                                     className={option.id === talent?.avatar_id ? "avatar-selection rounded-full cursor-pointer outline outline-offset-4 outline-secondary" : "avatar-selection rounded-full cursor-pointer "}
                                                     onClick={
                                                         () => onUpdateTalent(option.id, option.gender_id)
                                                     }
                                                />
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className='flex items-center justify-center gap-4'>
                                <div className={'flex justify-center items-center'}>
                                    <div className={'bg-secondary text-white  px-6 py-2  rounded-full cursor-pointer'} onClick={() => {
                                        navigate('/talent/profil')
                                    }}>
                                        <i className='fa-solid fa-arrow-left mr-1'></i> {intl.formatMessage({id: 'back'})}
                                    </div>
                                </div>
                                {
                                    talent?.is_portage ?
                                        <div className={'flex flex-col justify-center items-center mt-8 mb-8'}>
                                            <button className={"!px-6 py-2 !rounded-full !bg-secondary !text-white w-36"}
                                                    onClick={() => {
                                                        navigate('/talent/candidature')
                                                    }}
                                                    >
                                                {intl.formatMessage({id: 'validate'})}
                                            </button>
                                        </div> :
                                        <ValidateButton message={intl.formatMessage({id: "modifpersonelData"})} />
                                }
                            </div>
                       

                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderFirstTime = () => {
        return (
            <div className="login-container">
                <header className="py-2 flex flex-col space-y-5 justify-center items-center">
                    <img src={require('./../../../assets/images/logo.png')} className="w-96" alt="Logo de Talenteum"/>

                    <h1 className="xl:w-1/3 py-3 px-6 rounded-full shadow-xxl font-sans font-semibold text-green-600 xl:text-lg text-center">
                        {intl.formatMessage({id: 'create.my.avatar'})}
                    </h1>
                </header>

                <main className="my-8 flex justify-center">
                    <div className={"flex flex-col w-4/5 px-6"}>
                        <div className={'flex space-x-6'}>
                            <div className={'flex flex-col space-y-8 flex-grow'}>

                                <div className="flex space-x-8">
                                    <div className="flex flex-col flex-1 p-10 space-y-4 shadow-xxl rounded-4xl">
                                        {renderSelectedAvatar()}
                                        <div className="grid grid-cols-3 md:grid-cols-6 lg:grid-cols-12 gap-4 p-2 overflow-y-auto">
                                            {avatar?.filter(avatar => avatar.type === 'talent').map(option => {
                                                return (
                                                    <img key={option.id}
                                                         src={require('./../../../assets/avatars/' + option.filename)}
                                                         alt="Avatar"
                                                         className={option.id === talent?.avatar_id ? "avatar-selection rounded-full cursor-pointer outline outline-offset-4 outline-secondary" : "avatar-selection rounded-full cursor-pointer "}
                                                         onClick={
                                                             () => onUpdateTalent(option.id, option.gender_id)
                                                         }
                                                    />
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                                {talent.avatar_id ? renderButton() : ''}
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }


    return  talent && talent?.profile_status == 'new' ? renderFirstTime() : renderNormalPage()

}

export default injectIntl(PersonalData)
