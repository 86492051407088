import React, {useEffect, useState} from 'react'
import {injectIntl} from 'react-intl'
import { toast } from 'react-toastify';


const ValidateButton = ({intl,message, onClickSubmit}) => {

    const [open, setOpen] = React.useState(false);

    return (
        <div  className={'flex flex-col justify-center items-center mt-8 mb-8'}>
            <button  className={open ? "!px-6 py-2 !rounded-full !bg-green-600 !text-white w-36" : "!px-6 py-2 !rounded-full !bg-secondary !text-white w-36"}
                disabled={open}
                onClick={() => {
                    message &&  toast.success(message)
                    onClickSubmit && onClickSubmit()
                    setOpen(true);
                    setTimeout(function() {
                        setOpen(false);
                    }, 500)
                }
            }>
                {intl.formatMessage({id: 'validate'})}
            </button>

        </div>
    )
}

export default injectIntl(ValidateButton)

