export const pagesNav = [
    {
        name: 'job_offer',
        path: '/talent/search/job_offers',
        icon: 'fa-solid fa-magnifying-glass',
        direct_link: false,
        options: [
            'You are looking for an opportunity?',
            "Browse job offers, your favorites, and track your applications"
        ],
        subs: [
            // {
            //     name: 'my.cv',
            //     path: '/talent/preview',
            //     icon: 'fa-solid fa-file',
            //     options: [
            //         'Modifiez votre parcours',
            //         'Ajustez vos compétences',
            //         'Changez votre rémunération',
            //     ],
            //     button_name: "Consulter",
            //     break: false
            // },
            {
                name: 'consult_job_offer',
                path: '/talent/search/job_offers',
                icon: 'fa-solid fa-magnifying-glass',
                options: [
                    'Filtrez les offres',
                    'Comparez les offres',
                    'Postulez aux offres',
                ],
                button_name: "Consulter",
                break: false
            },
            {
                name: 'consult_favorite',
                path: '/talent/search/job_offers/favorites',
                icon: 'fa-solid fa-user-check',
                options: [
                    'Constituez vos favoris',
                    'Comparez vos favoris',
                    'Gérez vos favoris',
                ],
                button_name: "Consulter",
                break: false
            },
            {
                name: 'view_applications',
                path: '/talent/candidature',
                icon: 'fa-solid fa-wand-magic-sparkles',
                options: [
                    'Suivez vos candidatures',
                    'Passez des entretiens',
                    'Signez votre contrat',
                ],
                button_name: "Consulter",
                break: false
            },
        ]
    },
    {
        name: 'portage',
        path: '/talent/portage',
        icon: 'fa-solid fa-user-shield',
        direct_link: true,
        options: [
            'Already found a project?',
            "Request an umbrella contract and invite your company to join Talenteum"
        ],
        subs: [
            {
                name: 'port_salary',
                path: '/talent/port_salary',
                icon: 'fa-solid fa-address-card',
                options: [
                    'Précisez votre rémunération souhaitée',
                    'Communiquez-nous les coordonnées de l\'entreprise',
                    'Signez votre contrat de portage salarial',
                    'Débutez votre collaboration à la date convenue avec l\'entreprise',
                ],
                button_name: "Demander",
                break: false
            },
            {
                name: 'port_independant',
                path: '/talent/port_independant',
                icon: 'fa-solid fa-user-tag',
                options: [
                    'Précisez votre rémunération souhaitée',
                    'Communiquez-nous les coordonnées de l\'entreprise',
                    'Signez votre contrat de travailleur indépendant',
                    'Débutez votre collaboration à la date convenue avec l\'entreprise',
                ],
                button_name: "Demander",
                break: false
            }
        ]
    },
    {
        name: 'parainage',
        path: '/talent/parainage',
        icon: 'fa-solid fa-hand-holding-hand',
        direct_link: true,
        options: [
            'Ready to grow the networks?',
            "Refer a talent or a company, and manage your community"
        ],
        subs: [
            {
                name: 'refer_talent',
                path: '/talent/refer_talent',
                icon: 'fa-solid fa-people-carry-box',
                options: [
                    'Invitez un talent à devenir membre',
                    'Contribuez au rayonnement de la communauté Talenteum',
                    'Percevez des compléments de revenu',
                ],
                button_name: "Parrainer",
                break: false
            },
            {
                name: 'refer_entreprise',
                path: '/talent/refer_entreprise',
                icon: 'fa-solid fa-hand-holding-dollar',
                options: [
                    'Invitez une entreprise à devenir membre',
                    'Donnez-lui accès à un vivier de talents',
                    'Percevez des compléments de revenu',
                ],
                button_name: "Parrainer",
                break: false
            },
            /*{
                name: 'my_referrals',
                path: '/company/my_referrals',
                icon: 'fa-solid fa-people-arrows',
                options: [
                    'Consultez mes filleuls',
                    'Suivez les sous-filleuls',
                    'Gérez les primes de parrainage',
                ],
                button_name: "Consulter",
                break: false
            }*/
        ]
    }
]

export const profileMenuTuil = [
    {
        name: "my.personal.data",
        icon:  <svg xmlns="http://www.w3.org/2000/svg" height="80px" viewBox="0 -960 960 960" width="80px" fill="#16a34a"><path d="M480-400q33 0 56.5-23.5T560-480q0-33-23.5-56.5T480-560q-33 0-56.5 23.5T400-480q0 33 23.5 56.5T480-400ZM320-240h320v-23q0-24-13-44t-36-30q-26-11-53.5-17t-57.5-6q-30 0-57.5 6T369-337q-23 10-36 30t-13 44v23ZM740-80H220q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h341l239 239v501q0 24-18 42t-42 18Zm0-60v-474L534-820H220v680h520Zm-520 0v-680 680Z"/></svg>,
        description: "my.personal.data.description",
        link: "/talent/profil-personal-data"
    },
    {
        name: "job.search.preference",
        icon: <svg xmlns="http://www.w3.org/2000/svg" height="80px" viewBox="0 -960 960 960" width="80px" fill="#16a34a"><path d="M796-121 533-384q-30 26-70 40.5T378-329q-108 0-183-75t-75-181q0-106 75-181t182-75q106 0 180.5 75T632-585q0 43-14 83t-42 75l264 262-44 44ZM377-389q81 0 138-57.5T572-585q0-81-57-138.5T377-781q-82 0-139.5 57.5T180-585q0 81 57.5 138.5T377-389Zm-74-89 29-87-73-56h87l30-87 30 87h86l-73 56 29 87-72-55-73 55Z"/></svg>,
        description: "job.search.preference.description",
        link: "/talent/preference"
    },
    {
        name: "personal.document",
        icon: <svg xmlns="http://www.w3.org/2000/svg" height="80px" viewBox="0 -960 960 960" width="80px" fill="#16a34a"><path d="M140-160q-23 0-41.5-18.5T80-220v-520q0-23 18.5-41.5T140-800h281l60 60h339q23 0 41.5 18.5T880-680H455l-60-60H140v520l102-400h698L833-206q-6 24-22 35t-41 11H140Zm63-60h572l84-340H287l-84 340Zm0 0 84-340-84 340Zm-63-460v-60 60Z"/></svg>,
        description: "personal.document.description",
        link: "/talent/documents"
    },
    {
        name: "password",
        icon: <svg xmlns="http://www.w3.org/2000/svg" height="80px" viewBox="0 -960 960 960" width="80px" fill="#16a34a"><path d="M80-200v-61h800v61H80Zm38-254-40-22 40-68H40v-45h78l-40-68 40-22 38 67 38-67 40 22-40 68h78v45h-78l40 68-40 22-38-67-38 67Zm324 0-40-24 40-68h-78v-45h78l-40-68 40-22 38 67 38-67 40 22-40 68h78v45h-78l40 68-40 24-38-67-38 67Zm324 0-40-24 40-68h-78v-45h78l-40-68 40-22 38 67 38-67 40 22-40 68h78v45h-78l40 68-40 24-38-67-38 67Z"/></svg>,
        description: "password.description",
        link: "/talent/mdp"
    }
]

export const pagesAccNav = [
    {
        name: 'account',
        path: '/account',
        icon: 'fa-solid fa-circle-user',
        options: [
            'Notez vos préférences',
            'Modifiez vos données personnelles',
            'Changez votre mot de passe',
        ],
        subs: [
            // {
            //     name: 'preferences',
            //     path: '/talent/preference',
            //     icon: 'fa-solid fa-asterisk',
            //     options: [
            //         'Changez votre devise, fuseau horaire et langue',
            //     ],
            //     button_name: "Consulter",
            //     break: false
            // },
            // {
            //     name: 'avatar',
            //     path: '/talent/avatar',
            //     icon: 'fa-solid fa-id-badge',
            //     options: [
            //         'Changez votre avatar',
            //     ],
            //     button_name: "Consulter",
            //     break: true
            // },
            // {
            //     name: 'coordonnes',
            //     path: '/talent/personal-data',
            //     icon: 'fa-solid fa-address-book',
            //     options: [
            //         'Mettre à jour vos données perso',
            //     ],
            //     button_name: "Consulter",
            //     break: false
            // },
            // {
            //     name: 'my.documents',
            //     path: '/talent/documents',
            //     icon: 'fa-solid fa-id-card-clip',
            //     options: [
            //         'Téléchargez vos documents',
            //     ],
            //     button_name: "Consulter",
            //     break: true
            // },
            {
                name: 'my.cv',
                path: '/talent/preview',
                icon: 'fa-solid fa-file',
                options: [
                    'Modifiez votre parcours',
                    'Ajustez vos compétences',
                    'Changez votre rémunération',
                ],
                button_name: "Consulter",
                break: false
            },
            {
                name: 'my.profile',
                path: '/talent/profil',
                icon: 'fa-solid fa-file',
                options: [
                    'Mes données personnelles',
                    'Mes préférences de recherche d’emploi',
                    'Mes documents personnels',
                    'Mot de passe'
                ],
                button_name: "Consulter",
                break: true
            },
            {
                name: 'my.contract',
                path: '/talent/my_contract',
                icon: 'fa-solid fa-file-contract',
                options: [
                    'Consulter votre timesheet',
                ],
                button_name: "Consulter",
                break: true
            },
            /* {
                name: 'timesheet',
                path: '/talent/timesheet',
                icon: 'fa-solid fa-file',
                options: [
                    'Mettre à jour votre timesheet',
                ],
                button_name: "Consulter",
                break: false
            },
            {
                name: 'expense.report',
                path: '/talent/expense_report',
                icon: 'fa-solid fa-file',
                options: [
                    'Mettre à jour vos notes de frais',
                ],
                button_name: "Consulter",
                break: false
            },
            {
                name: 'holidays',
                path: '/talent/holiday',
                icon: 'fa-solid fa-calendar',
                options: [
                    'Demander des congés',
                ],
                button_name: "Consulter",
                break: false
            },
            {
                name: 'bonus',
                path: '/talent/bonus',
                icon: 'fa-solid fa-award',
                options: [
                    'Accepter des primes',
                ],
                button_name: "Consulter",
                break: false
            }, */
            {
                name: 'invoices',
                path: '/talent/renumeration',
                icon: 'fa-solid fa-euro-sign',
                options: [
                    'Téléchargez vos fiche de paye',
                ],
                button_name: "Consulter",
                break: true
            },
            // {
            //     name: 'mdp',
            //     path: '/talent/mdp',
            //     icon: 'fa-solid fa-lock',
            //     options: [
            //         'Changez votre mot de passe'
            //     ],
            //     button_name: "Consulter",
            //     break: false
            // },
            {
                name: 'disconnect',
                path: '/disconnect',
                icon: 'fa-solid fa-right-from-bracket',
                options: [
                    'Déconnectez-vous',
                ],
                button_name: "Déconnexion",
                break: false
            }
        ]
    },
]

export const pageLayoutMaxWidth = '1000px'