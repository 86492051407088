// Import the redux-saga/effects
import {call, put, takeEvery} from 'redux-saga/effects'

// Import all action and api's
import {
    AUTO_LOGIN,
    GET_CURRENT_USER,
    REGISTER_RESEND_EMAIL,
    REGISTER_USER,
    RESET_PWD,
    SEND_OTP,
    SIGNIN_USER,
    SIGNOUT_USER,
    VALIDATE_OTP,
    REGISTER_USER_LINKEDIN
} from '../action-types'

// Import all api's
import {
    autoLoginRequest,
    getCurrentUserRequest,
    registerResendEmailRequest,
    registerUserRequest,
    resetPwdRequest,
    sendOtpRequest,
    signInUserWithEmailPasswordRequest,
    signOutRequest,
    validateOtpRequest,
    registerUserLinkedinRequest
} from '../api/Auth'

import {
    getCurrentUserSuccess,
    registerResendEmailSuccess, registerUserLinkedinSuccess,
    registerUserSuccess,
    resetPwdSuccess,
    sendOtpSuccess,
    userSignInSuccess,
    userSignOutSuccess,
    validateOtpSuccess,
} from '../actions/Auth'

import {fetchError, fetchErrorCustom, fetchExpired, fetchStart, fetchSuccess} from '../actions/Common'
import { toast } from 'react-toastify'
// import { setRolesState } from '../actions/Roles'

// Here's the unique part, generator function*, function with asterisk(*)

function* signInUserWithEmailPassword({payload}) {
    const {email, password} = payload

    try {
        yield put(fetchStart())
        const response = yield call(signInUserWithEmailPasswordRequest, email, password)
        if (response.status === 200) {
            yield put(userSignInSuccess(response.data))
            yield put(fetchSuccess())
        } else if (response.status === 400) {
            yield put(fetchErrorCustom('email_not_exists'))
        } else if (response.status === 422) {
            yield put(fetchErrorCustom('incorrect_credentials'))
        } else if (response.status === 401) {
            yield put(fetchErrorCustom('account_blocked'))
        } else {
            yield put(fetchErrorCustom('error'))
        }
    } catch (error) {
        yield put(fetchErrorCustom('error'))
    }
}

function* getCurrentUser({token}) {

    try {
        yield put(fetchStart())
        const response = yield call(getCurrentUserRequest, token)

        if (response.status === 200) {
            yield put(getCurrentUserSuccess(response.data))
            yield put(fetchSuccess())
        } else if (response.status === 400) {
            yield put(fetchErrorCustom('incorrect_credentials'))
        } else if (response.status === 401) {
            yield put(fetchExpired())
        } else {
            yield put(fetchErrorCustom('error'))
        }
    } catch (error) {
        yield put(fetchErrorCustom('error'))
    }
}

function* signOut({token}) {

    try {
        yield put(fetchStart())
        const response = yield call(signOutRequest, token)

        if (response.status === 200) {
            yield put(userSignOutSuccess(response.data))
            yield put(fetchSuccess())
        } else if (response.status === 400) {
            yield put(fetchErrorCustom('incorrect_credentials'))
        } else if (response.status === 401) {
            yield put(fetchExpired())
        } else {
            yield put(fetchErrorCustom('error'))
        }
    } catch (error) {
        yield put(fetchErrorCustom('error'))
    }
}

function* sendOtp({token, form}) {

    try {
        yield put(fetchStart())
        const response = yield call(sendOtpRequest, token, form)
        if (response.status === 200) {
            yield put(sendOtpSuccess(response.data))
            yield put(fetchSuccess())
        } else if (response.status === 405) {
            yield put(fetchErrorCustom('phone_exist'))
        }  else if (response.status === 422) {
            yield put(fetchErrorCustom('incorrect_otp'))
        } else {
            yield put(fetchErrorCustom('error'))
        }
    } catch (error) {
        yield put(fetchErrorCustom('error'))
    }
}

function* validateOtp({token, form}) {

    try {
        yield put(fetchStart())
        const response = yield call(validateOtpRequest, token, form)
        if (response.status === 200) {
            yield put(validateOtpSuccess(response.data))
            yield put(fetchSuccess())
        } else if (response.status === 422) {
            yield put(fetchErrorCustom('incorrect_otp'))
        } else {
            yield put(fetchErrorCustom('error'))
        }
    } catch (error) {
        yield put(fetchErrorCustom('error'))
    }
}

function* resetPwd({token, form, intl}) {
    try {
        //yield put(fetchStart())
        const response = yield call(resetPwdRequest, token, form)

        if (response.status === 200) {
            yield put(resetPwdSuccess(response.data))
            yield put(fetchSuccess())
            toast.success(intl.formatMessage({id: "changepwd"}))
        }

        if (response.status === 401) {
            yield put(fetchExpired())
            toast.error(intl.formatMessage({ id: "error401" }));
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
            toast.error(intl.formatMessage({ id: "server_error_portage" }));
        }
    } catch (error) {
        console.log(error)
        toast.error(intl.formatMessage({ id: "global_error_portage" }));
    }
}

function* registerUser({form}) {
    try {
        yield put(fetchStart())
        const response = yield call(registerUserRequest, form)
        if (!response) {
            yield put(fetchErrorCustom('error'))
        } else if (response.status === 200) {
            yield put(registerUserSuccess(response.data))
            yield put(fetchSuccess())
        } else if (response.status === 400 || response.status === 422) {
            yield put(fetchErrorCustom('incorrect_credentials'))
        } else if (response.status === 401) {
            yield put(fetchErrorCustom('account_blocked'))
        } else if (response.status === 405) {
            yield put(fetchErrorCustom('email_exist'))
        } else {
            yield put(fetchErrorCustom('error'))
        }
    } catch (error) {
        console.log(error)
    }
}

function* registerUserLinkedin({token, form}) {
    try {
        yield put(fetchStart())
        const response = yield call(registerUserLinkedinRequest, token, form)
        if (!response) {
            yield put(fetchErrorCustom('error'))
        } else if (response.status === 200) {
            yield put(registerUserLinkedinSuccess(response.data))
            yield put(fetchSuccess())
        } else if (response.status === 400 || response.status === 422) {
            yield put(fetchErrorCustom('incorrect_credentials'))
        } else if (response.status === 401) {
            yield put(fetchErrorCustom('account_blocked'))
        } else if (response.status === 405) {
            yield put(fetchErrorCustom('email_exist'))
        } else {
            yield put(fetchErrorCustom('error'))
        }
    } catch (error) {
        console.log(error)
    }
}

function* registerResendEmail({form, intl}) {
    try {
        yield put(fetchStart())
        const response = yield call(registerResendEmailRequest, form)

        if (response.status === 200) {
            yield put(registerResendEmailSuccess(response.data))
            yield put(fetchSuccess())
            toast.success(intl.formatMessage({id: "validationMsgRsent"}))
        } else if (response.status === 400 || response.status === 422) {
            yield put(fetchErrorCustom('no_email'))
        } else if (response.status === 401) {
            yield put(fetchErrorCustom('account_blocked'))
        } else {
            yield put(fetchErrorCustom('error'))
        }
    } catch (error) {
        console.log(error)
    }
}

function* autoLogin({uid}) {
    try {
        yield put(fetchStart())
        const response = yield call(autoLoginRequest, uid)
        if (response.status === 200) {
            yield put(userSignInSuccess(response.data))
            yield put(fetchSuccess())
        } else if (response.status === 400 || response.status === 422) {
            yield put(fetchErrorCustom('incorrect_credentials'))
        } else if (response.status === 401) {
            yield put(fetchErrorCustom('account_blocked'))
        } else {
            yield put(fetchErrorCustom('error'))
        }
    } catch (error) {
        console.log(error)
    }
}

// Export the saga (todo-saga)
export default function* todoSaga() {
    yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword)
    yield takeEvery(SIGNOUT_USER, signOut)
    yield takeEvery(RESET_PWD, resetPwd)
    yield takeEvery(SEND_OTP, sendOtp)
    yield takeEvery(VALIDATE_OTP, validateOtp)
    yield takeEvery(REGISTER_USER, registerUser)
    yield takeEvery(REGISTER_USER_LINKEDIN, registerUserLinkedin)
    yield takeEvery(REGISTER_RESEND_EMAIL, registerResendEmail)
    yield takeEvery(GET_CURRENT_USER, getCurrentUser)
    yield takeEvery(AUTO_LOGIN, autoLogin)
}