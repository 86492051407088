import {SET_EDU_EXP_CERT_STATE, UPDATE_EDU_EXP_CERT, UPDATE_EDU_EXP_CERT_SUCCESS,} from '../action-types'

const INIT_STATE = {
    edu_exp_cert: false,
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case UPDATE_EDU_EXP_CERT: {
            return {...state, edu_exp_cert: false}
        }

        case UPDATE_EDU_EXP_CERT_SUCCESS: {
            return {...state, edu_exp_cert: true}
        }

        case SET_EDU_EXP_CERT_STATE: {
            return {
                ...state,
                ...action?.payload
            }
        }

        default:
            return state
    }
}
