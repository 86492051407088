// Import the redux-saga/effects
import {call, put, takeEvery} from 'redux-saga/effects'

// Import all action and api's
import {
    GET_COMPANY,
    GET_CONTACT, UPDATE_COMPANY, UPDATE_CONTACT,
    DELETE_CONTACT, SEND_INVITATION, GET_COMPANY_GROUPS, GET_MODULE_GROUPS,
    GET_COMPANY_TEAM, GET_COMPANY_INVOICE, UPDATE_COMPANY_INVOICE, GET_COMPANY_INVOICE_SINGLE,
    GET_COMPANY_INVOICE_TICKET, GET_COMPANY_INVOICE_VALIDATE, GET_COMPANY_INVOICE_VALIDATE_ITEM,
    GET_RECRUITER_LIST_COMPANIES
} from '../action-types'

// Import all api's
import {
    getContactRequest,
    updateContactRequest,
    deleteContactRequest,
    sendInvitationRequest,
    getModuleRequest, updateCompanyInvoiceRequest, updateCompanyInvoiceSingleRequest,
    updateCompanyInvoiceTicketRequest, updateCompanyInvoiceValidateRequest, updateCompanyInvoiceValidateItemRequest,
    updateContactPhoneRequest, getCompaniesListRecruiterRequest
} from '../api/Company'

import {
    getCompanySuccess,
    getContactSuccess,
    updateCompanySuccess,
    updateContactSuccess,
    deleteContactSuccess,
    sendInvitationSuccess, getCompanyGroupsSuccess, getModuleGroupsSuccess,
    getCompanyTeamSuccess, getCompanyInvoiceSuccess, updateCompanyInvoiceSuccess, getCompanyInvoiceSingleSuccess,
    getCompanyInvoiceTicketSuccess, getCompanyInvoiceValidateSuccess,getCompanyInvoiceValidateItemSuccess,
    updateContactPhoneSuccess, getRecruiterListCompaniesSuccess
} from '../actions/Company'

import {fetchError, fetchExpired, fetchStart, fetchSuccess} from '../actions/Common'
import {graphqlGroupFields} from "../../utils/constant";
import { toast } from 'react-toastify'

function* getContact({token, query}) {
    try {
        yield put(fetchStart())
        const response = yield call(getContactRequest, token, query)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getContactSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* updateContact({token, query, typeUpdate, intl, loading = false}) {
    try {
        if (loading) {
            yield put(fetchStart())
        }
        const response = yield call(updateContactRequest, token, query)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                if (loading) {
                    yield put(updateContactSuccess(response.data))
                }
                yield put(fetchSuccess())
                typeUpdate === "preferences" &&
                toast.success(intl.formatMessage({id: "preferencesUpdated"}));
                typeUpdate === "coordonées" &&
                toast.success(intl.formatMessage({id: "contactDetailsUpdated"}));
                typeUpdate === "avatar" && toast.success(intl.formatMessage({id: "avatarUpdated"}));
                typeUpdate === "personalInfo" &&
                toast.success(intl.formatMessage({id: "personalInfoUpdated"}));
                typeUpdate === "collegue" &&
                toast.success(intl.formatMessage({id: "contactList"}));
                typeUpdate === "success_form" &&
                toast.success(intl.formatMessage({id: "infoGenerale"}));
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
            toast.error(intl.formatMessage({ id: "error401" }));
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
            toast.error(intl.formatMessage({ id: "server_error_portage" }));
        }
    } catch (error) {
        console.log(error)
        toast.error(intl.formatMessage({ id: "global_error_portage" }));
    }
}

function* updateContactPhone({token, values, loading = false, intl}) {
    try {
        if (loading) {
            yield put(fetchStart())
        }
        const response = yield call(updateContactPhoneRequest, token, values)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                if (loading) {
                    yield put(updateContactPhoneSuccess(response.data))
                }
                yield put(fetchSuccess())
                toast.success(intl.formatMessage({id: "success_form"}));
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
            toast.error(intl.formatMessage({ id: "error401" }));
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
            toast.error(intl.formatMessage({ id: "server_error_portage" }));
        }
    } catch (error) {
        console.log(error)
        toast.error(intl.formatMessage({ id: "global_error_portage" }));
    }
}

function* getCompany({token, query}) {
    try {
        yield put(fetchStart())
        const response = yield call(getContactRequest, token, query)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getCompanySuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* updateCompany({token, query, loading = false, intl}) {
    try {
        if (loading) {
            yield put(fetchStart())
        }
        const response = yield call(updateContactRequest, token, query)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                if (loading) {
                    yield put(updateCompanySuccess(response.data))
                }
                yield put(fetchSuccess())
                toast.success(intl.formatMessage({ id: "success_form" }));
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
            toast.error(intl.formatMessage({ id: "error401" }));
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
            toast.error(intl.formatMessage({ id: "server_error_portage" }));
        }
    } catch (error) {
        console.log(error)
        toast.error(intl.formatMessage({ id: "global_error_portage" }));
    }
}

function* deleteContact({ token, query, loading = false }) {
    try {
        const response = yield call(deleteContactRequest, token, query);
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data));
            } else {
                if (loading) {
                    yield put(deleteContactSuccess(response.data));
                }
                yield put(fetchSuccess());
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired());
        }
        if (response.status === 500) {
            yield put(fetchError(response.data));
        }
    } catch (error) {
        console.log(error);
    }
}

function* sendInvitation({ token, id }) {
    try {
        const response = yield call(sendInvitationRequest, token, id);
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {

            } else {
                yield put(sendInvitationSuccess(response));
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired());
        }

    } catch (error) {
        console.log(error);
    }
}

function* getCompanyGroups({ token, id }) {
    try {
        yield put(fetchStart());
        const response = yield call(getContactRequest, token, `
            {
                groupByCompanyId(
                    company_id: ${id}
                ){
                    ${graphqlGroupFields}
                }
            }
        `);
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data));
            } else {
                yield put(getCompanyGroupsSuccess(response.data));
                yield put(fetchSuccess());
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired());
        }
        if (response.status === 500) {
            yield put(fetchError(response.data));
        }
    } catch (error) {
        console.log(error);
    }
}

function* getCompanyTeam({token, query}) {
    try {
        yield put(fetchStart())
        const response = yield call(getContactRequest, token, query)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getCompanyTeamSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getCompanyInvoice({token, query}) {
    try {
        yield put(fetchStart())
        const response = yield call(getContactRequest, token, query)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getCompanyInvoiceSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* updateCompanyInvoice({token, query}) {
    try {
        yield put(fetchStart())
        const response = yield call(updateCompanyInvoiceRequest, token, query)
        if (response.status === 200) {
            yield put(updateCompanyInvoiceSuccess(response.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* updateCompanyInvoiceSingle({token, id}) {
    try {
        yield put(fetchStart())
        const response = yield call(updateCompanyInvoiceSingleRequest, token, id)
        if (response.status === 200) {
            yield put(getCompanyInvoiceSingleSuccess(response.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* updateCompanyInvoiceTicket({token, form}) {
    try {
        yield put(fetchStart())
        const response = yield call(updateCompanyInvoiceTicketRequest, token, form)
        if (response.status === 200) {
            yield put(getCompanyInvoiceTicketSuccess(response.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* updateCompanyInvoiceValidate({token, id}) {
    try {
        yield put(fetchStart())
        const response = yield call(updateCompanyInvoiceValidateRequest, token, id)
        if (response.status === 200) {
            yield put(getCompanyInvoiceValidateSuccess(response.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* updateCompanyInvoiceValidateItem({token, form}) {
    try {
        yield put(fetchStart())
        const response = yield call(updateCompanyInvoiceValidateItemRequest, token, form)
        if (response.status === 200) {
            yield put(getCompanyInvoiceValidateItemSuccess(response.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getRecruiterListCompanies({token}) {
    try {
        yield put(fetchStart())
        const response = yield call(getCompaniesListRecruiterRequest, token)
        if (response.status === 200) {
            yield put(getRecruiterListCompaniesSuccess(response.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

// Export the saga (todo-saga)
export default function* todoSaga() {
    yield takeEvery(GET_CONTACT, getContact)
    yield takeEvery(UPDATE_CONTACT, updateContact)
    yield takeEvery(UPDATE_CONTACT, updateContactPhone)
    yield takeEvery(GET_COMPANY, getCompany)
    yield takeEvery(UPDATE_COMPANY, updateCompany)
    yield takeEvery(DELETE_CONTACT, deleteContact)
    yield takeEvery(SEND_INVITATION, sendInvitation)
    yield takeEvery(GET_COMPANY_GROUPS, getCompanyGroups)
    yield takeEvery(GET_COMPANY_TEAM, getCompanyTeam)
    yield takeEvery(GET_COMPANY_INVOICE, getCompanyInvoice)
    yield takeEvery(UPDATE_COMPANY_INVOICE, updateCompanyInvoice)
    yield takeEvery(GET_COMPANY_INVOICE_SINGLE, updateCompanyInvoiceSingle)
    yield takeEvery(GET_COMPANY_INVOICE_TICKET, updateCompanyInvoiceTicket)
    yield takeEvery(GET_COMPANY_INVOICE_VALIDATE, updateCompanyInvoiceValidate)
    yield takeEvery(GET_COMPANY_INVOICE_VALIDATE_ITEM, updateCompanyInvoiceValidateItem)
    yield takeEvery(GET_RECRUITER_LIST_COMPANIES, getRecruiterListCompanies)
}