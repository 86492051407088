import React, {Suspense} from 'react'
import {
    BrowserRouter,
    Navigate,
    Routes,
    Route,
} from 'react-router-dom'
import {Provider} from 'react-redux'
import store from './redux/store'
import './App.scss'
import SuspenseFallback from './containers/SuspenseFallback'
import {StyledEngineProvider} from '@mui/material/styles'
import Login from './views/Auth/Login'
import LoginLang from './views/Auth/LoginLang'
import Register from './views/Auth/Register'
import RegisterLang from './views/Auth/RegisterLang'
import Phone from "./views/Auth/Phone";
import RequireAuth from './containers/RequireAuth'
import AppIntlProvider from './containers/AppIntlProvider'
import PageContainer from './containers/PageContainer'
import Autologin from "./views/Auth/Autologin";
import InvitationRefused from "./views/Auth/InvitationRefused";
import RegisterOAuth from "./views/Auth/RegisterOAuth";
import RegisterLinkedin from "./views/Auth/RegisterLinkedin";
import Unsubscribe from './views/Unsubscribe'
import Rating from './views/Talent/Rating'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify'
import { NavigationProvider } from './containers/NavigationContext';
import HistoryTracker from './containers/HistoryTracker';

function App() {

    return (
        <Provider store={store}>

            <AppIntlProvider>
                <StyledEngineProvider injectFirst>
                    <div className="App">
                        <Suspense fallback={<SuspenseFallback/>}>
                            <PageContainer>
                                <NavigationProvider>
                                    <ToastContainer />
                                    <BrowserRouter>
                                        <HistoryTracker />
                                        <Routes>
                                            <Route path="/" element={<Navigate replace to="/login" />} />
                                            <Route path="/loginlang/:lang" element={<LoginLang />} />
                                            <Route path={'/autologin'} element={<Autologin />} />
                                            <Route path="/login" element={<Login />} />
                                            <Route path="/login/:first" element={<Login />} />
                                            <Route path="/registeroauth" element={<RegisterOAuth />} />
                                            <Route path="/oauth/linkedin" element={<RegisterLinkedin />} />
                                            <Route path="/registerlang/:type/:lang" element={<RegisterLang />} />
                                            <Route path="/register/:type" element={<Register />} />
                                            <Route path="/phone" element={<Phone />} />
                                            <Route path="/invitation/refused" element={<InvitationRefused />} />
                                            <Route path="unsubscribe" element={<Unsubscribe />} />
                                            <Route path="talent/rating" element={<Rating/>}/>
                                            <Route
                                                path="/*"
                                                element={
                                                    <RequireAuth />
                                                }
                                            />

                                        </Routes>
                                    </BrowserRouter>
                                </NavigationProvider>
                            </PageContainer>
                        </Suspense>
                    </div>
                </StyledEngineProvider>
            </AppIntlProvider>
        </Provider>
    );
}

export default App;
